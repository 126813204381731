import { KeysOfType } from 'components/common/Chart'
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'

type BitData = {
  [key: `bit${number}`]: number
}

type Props<T, N extends number> = {
  data: T[]
  xAxisDataKey: KeysOfType<T, number>
  yAxisDataKey: KeysOfType<T, number>
  bits: N
  bitLabels: string[]
  bitColors: string[]
  syncId?: string
  labelWidth?: number
}

function splitIntoBits(
  num: number,
  bits: number,
  bitHeight: number,
  bitOnHeight: number,
): BitData {
  const bitData: BitData = {}
  for (let i = 0; i < bits; i++) {
    bitData[`bit${i}`] = i * bitHeight + ((num >> i) & 1 ? bitOnHeight : 0)
  }
  return bitData
}

function BinaryChartRenderer<T, N extends number>({
  data,
  xAxisDataKey,
  yAxisDataKey,
  bits,
  bitLabels,
  bitColors,
  syncId,
  labelWidth,
}: Props<T, N>) {
  const bitHeight = 100
  const maxHeight = bits * bitHeight
  const bitOnHeight = bitHeight * 0.8

  const bitData = data.map((d) => {
    const num = d[yAxisDataKey] as number
    return {
      ...splitIntoBits(num, bits, bitHeight, bitOnHeight),
      [xAxisDataKey]: d[xAxisDataKey],
    }
  })

  return (
    <ResponsiveContainer height={'100%'} width={'100%'}>
      <ComposedChart data={bitData} syncId={syncId}>
        <YAxis
          allowDataOverflow
          domain={[0, maxHeight]}
          interval={0}
          tickCount={bits}
          tickFormatter={(value) => bitLabels[Math.floor(value / bitHeight)]}
          ticks={Array.from({ length: bits }, (_, i) => i * bitHeight)}
          type="number"
          width={labelWidth}
        />
        <CartesianGrid stroke="#ccc" vertical={false} />
        <XAxis dataKey={xAxisDataKey as string} />

        {Array.from({ length: bits }, (_, i) => (
          <Line
            key={i}
            dataKey={`bit${i}`}
            dot={false}
            isAnimationActive={false}
            stroke={bitColors[i]}
            strokeWidth={1}
            type="step"
          />
        ))}

        <Tooltip
          formatter={(value, name: string) => {
            const bit = parseInt(name.replace('bit', ''), 10)
            const isActive = (value as number) % bitHeight === bitOnHeight
            return [isActive ? 'ON' : 'OFF', bitLabels[bit]]
          }}
          labelFormatter={() => ''}
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default BinaryChartRenderer
