import { Modal, ModalHeader } from '@tovala/component-library'
import { memo, useEffect, useRef, useState } from 'react'
import { FixedSizeList as List } from 'react-window'
import { getEnvVar } from 'utils/env'
import { sendOvenCommand } from 'utils/commands'
import {
  getAuthToken,
  logLevelColors,
  LogEntry,
  parseLogEntry,
  RawLog,
  useDeviceLogs,
} from 'utils/oatsApi'
import useWebsocket from 'utils/socketConnection'

const useDebugLogs = false

function parseLogs(log: string): LogEntry[] {
  const parsed = JSON.parse(log)
  if (!Array.isArray(parsed)) {
    throw new Error('Expected array, got object')
  }

  return (parsed as RawLog[]).map(parseLogEntry)
}

const Button = ({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick: () => void
}) => (
  <button
    className="text-white rounded-lg bg-orange-1 py-1 px-2 mx-2 opacity-90 hover:opacity-100"
    onClick={onClick}
  >
    {children}
  </button>
)

const nextMinuteMs = () => {
  const now = new Date()
  now.setSeconds(0)
  now.setMilliseconds(0)
  now.setMinutes(now.getMinutes() + 1)
  return now.getTime()
}

const OvenLogs = memo(function OvenLogs({ ovenId }: { ovenId: string }) {
  const [isTailing, setIsTailing] = useState<boolean>(true)

  const [logLevelModal, setLogLevelModal] = useState<boolean>(false)

  const { data: logs, setData: setLogs } = useWebsocket<RawLog>(
    'logs',
    `${getEnvVar('OATS_API_URL')}/api/oven/logs/${ovenId}/stream?token=${getAuthToken()}${
      useDebugLogs ? '&debug=true' : ''
    }`,
    parseLogs,
    { enabled: isTailing },
  )
  const [follow, setFollow] = useState(true)

  const windowSizeMs = 120 * 60 * 1000

  const listRef = useRef<List>(null)

  const [endTime, setEndTime] = useState<number>(Date.now())
  const [startTime, setStartTime] = useState<number>(Date.now() - windowSizeMs)
  const [selectedEndTime, setSelectedEndTime] = useState<number>(nextMinuteMs())
  const [selectedStartTime, setSelectedStartTime] = useState<number>(
    nextMinuteMs() - windowSizeMs,
  )

  const startDateRef = useRef<HTMLInputElement>(null)
  const endDateRef = useRef<HTMLInputElement>(null)

  const [timeChanged, setTimeChanged] = useState<boolean>(false)
  const [filter, setFilter] = useState<string>('')

  const filterRef = useRef<HTMLInputElement>(null)
  const updateFilter = () => {
    if (!filterRef.current) {
      return
    }

    setFilter(filterRef.current!.value)
  }

  const updateEndTime = () => {
    if (endDateRef.current) {
      const time = new Date(endDateRef.current.value || '').getTime()
      setTimeChanged(true)
      setSelectedEndTime(time)
    }
  }

  const updateStartTime = () => {
    if (startDateRef.current) {
      const time = new Date(startDateRef.current?.value || '').getTime()
      setTimeChanged(true)
      setSelectedStartTime(time)
    }
  }

  // Queue up the start / end times so that changing them doesn't immediately trigger a load
  // This way we can ensure that both are selected and the load button is hit before
  // actually making the (expensive) call to the server
  const loadHistorical = () => {
    // The user might put in a start time that is after the end time, swap the values if so
    if (!isTailing && selectedStartTime > selectedEndTime) {
      const endValue = endDateRef.current!.value
      endDateRef.current!.value = startDateRef.current!.value
      startDateRef.current!.value = endValue
      updateStartTime()
      updateEndTime()
    }
    setStartTime(selectedStartTime)
    setEndTime(selectedEndTime)
    setFollow(true)
    setLogs([])
  }
  const { data: historicalLogs, isFetching: isLogsLoading } = useDeviceLogs(
    ovenId,
    startTime,
    endTime,
  )

  useEffect(() => {
    setTimeChanged(false)
  }, [isLogsLoading])

  const deviceLogs = (historicalLogs ?? []) as LogEntry[]
  const liveLogs = (logs ?? []) as LogEntry[]
  const combinedLogs = (
    (isTailing
      ? (isLogsLoading ? [] : deviceLogs).concat(liveLogs)
      : isLogsLoading
        ? []
        : deviceLogs) as LogEntry[]
  ).filter((log) => log.message && log.message.includes(filter))

  useEffect(() => {
    if (!listRef.current || !combinedLogs.length || !follow || !isTailing) {
      return
    }

    listRef.current!.scrollToItem(combinedLogs.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [combinedLogs])

  useEffect(() => {
    if (follow) {
      listRef.current?.scrollToItem(combinedLogs.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [follow])

  const LogList = ({ items }: { items: LogEntry[] }) => (
    <List
      ref={listRef}
      // Prevents the list from jumping around when new logs are added that need a scrollbar
      // eslint-disable-next-line react/forbid-component-props
      className="box-content pb-3"
      height={400}
      itemCount={items.length}
      itemSize={28}
      width={'100%'}
    >
      {({ index, style }) => {
        const entry = combinedLogs[index]

        return (
          <div className="text-nowrap" style={style}>
            <span className="text-gray-400 font-mono">
              {new Date(entry.timestamp).toLocaleString()}{' '}
            </span>
            <span className={`${logLevelColors[entry.level]} font-mono`}>
              {entry.level}{' '}
            </span>
            <span className={logLevelColors[entry.level]}>{entry.message}</span>
          </div>
        )
      }}
    </List>
  )

  // Stupid JS not providing a way to get localized ISO time strings
  const getLocalISOTime = (timeMs: number) => {
    const offset = new Date().getTimezoneOffset()
    const date = new Date(timeMs - offset * 60 * 1000)

    // Nothing stops the user from entering garbage here, so we need to be defensive
    try {
      return date.toISOString().slice(0, -1)
    } catch (e) {
      return ''
    }
  }
  const defaultStartTime = getLocalISOTime(selectedStartTime)
  const defaultEndTime = getLocalISOTime(selectedEndTime)

  const [resetVersion, setResetVersion] = useState<number>(0)
  const resetLogs = () => {
    setIsTailing(true)
    setSelectedStartTime(nextMinuteMs() - windowSizeMs)
    setSelectedEndTime(nextMinuteMs())
    setTimeChanged(false)
    loadHistorical()
    setResetVersion((prev) => prev + 1)
  }
  const resetToNow = () => {
    setIsTailing(true)
    setSelectedEndTime(nextMinuteMs())
    loadHistorical()
    setResetVersion((prev) => prev + 1)
  }

  const downloadLogs = () => {
    const url = URL.createObjectURL(
      new Blob(
        combinedLogs.map((e) => e.raw + '\n'),
        { type: 'text/plain' },
      ),
    )
    window.open(url)
  }

  const tagRef = useRef<HTMLInputElement>(null)
  const levelRef = useRef<HTMLSelectElement>(null)
  const LogLevelModal = () => {
    const setLogLevel = async () => {
      try {
        await sendOvenCommand(ovenId, 'log_level', {
          tag: tagRef.current!.value,
          level: parseInt(levelRef.current!.value, 10),
        })
        setLogLevelModal(false)
      } catch (e) {
        console.error(e)
      }
    }

    return (
      <Modal onCloseModal={() => setLogLevelModal(false)}>
        <ModalHeader onClickClose={() => setLogLevelModal(false)}>
          <span className="text-xl font-bold">Set Log Level</span>
        </ModalHeader>

        <div className="m-5 text-md max-w-md flex gap-2">
          <input
            ref={tagRef}
            className="border border-grey-3 rounded-lg px-2 py-1"
            placeholder="TAG"
            type="text"
          ></input>

          <select
            ref={levelRef}
            className="border border-gray-3 rounded-lg px-2 py-1"
            defaultValue="INFO"
          >
            <option value="5">VERBOSE</option>
            <option value="4">DEBUG</option>
            <option value="3">INFO</option>
            <option value="2">WARNING</option>
            <option value="1">ERROR</option>
          </select>

          <Button onClick={() => setLogLevel()}>Save</Button>
        </div>
      </Modal>
    )
  }

  return (
    <div className="flex flex-col space-y-4 w-full bg-slate-50 rounded-lg shadow-lg">
      <div className="relative overflow-hidden">
        <div className="flex flex-wrap gap-1 items-center bg-blue-100 px-1 py-1">
          <span>View logs between</span>
          <div className={isTailing ? 'md:basis-full' : 'lg:basis-full'}>
            <input
              key={resetVersion}
              ref={startDateRef}
              className="border-2 w-[12.5em]"
              defaultValue={defaultStartTime}
              onChange={() => updateStartTime()}
              step={60}
              type="datetime-local"
            />
            <span> and </span>
            {isTailing ? (
              <span>
                <span className="font-bold">now</span>
                <Button
                  onClick={() => {
                    setIsTailing(false)
                    setSelectedEndTime(nextMinuteMs())
                  }}
                >
                  Set End Time
                </Button>
              </span>
            ) : (
              <span>
                <input
                  key={resetVersion}
                  ref={endDateRef}
                  className="border-2 w-[12.5em]"
                  defaultValue={defaultEndTime}
                  onChange={() => updateEndTime()}
                  step={60}
                  type="datetime-local"
                />
                <Button onClick={() => resetToNow()}>Now</Button>
              </span>
            )}
            {timeChanged && (
              <span className={isLogsLoading ? 'opacity-80' : 'opacity-100'}>
                <Button onClick={() => loadHistorical()}>Load</Button>
              </span>
            )}
          </div>
          <div className="flex-grow"></div>
          <div className="absolute top-1 right-0 text-gray-400">
            <span>Total logs:</span>
            <span className="font-mono pl-2">
              {isLogsLoading ? '...' : combinedLogs.length}
            </span>
            <Button onClick={() => resetLogs()}>Reset</Button>
          </div>
        </div>
        <div className="m-2">
          {isLogsLoading && (
            <div className="text-center text-gray-500 p-4 flex-1">
              Loading log history...
            </div>
          )}
          <LogList items={combinedLogs} />
        </div>
        <div className="p-1 bg-blue-100 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <input
              ref={filterRef}
              className="border-2 h-8 p-2 w-[12em]"
              onChange={() => updateFilter()}
              placeholder="Filter..."
            />
            <Button onClick={() => downloadLogs()}>
              Download {filter ? 'filtered' : ''} logs
            </Button>
            <Button
              onClick={() => {
                setLogLevelModal(true)
              }}
            >
              Set Log Levels
            </Button>
          </div>

          {isTailing && (
            <div className="flex items-center justify-items-end align-right">
              <div className="text-gray-500">
                {follow ? 'Following...' : ''}
              </div>
              <Button onClick={() => setFollow(!follow)}>
                {follow ? 'Scroll' : 'Follow'}
              </Button>
            </div>
          )}
        </div>
      </div>
      {logLevelModal && <LogLevelModal />}
    </div>
  )
})

export default OvenLogs
