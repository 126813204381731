export const DEFAULT_ROUTINE_ID = '00000000-0000-0000-0000-000000000000'

const BASE_LOOKUP_URL = 'https://m.tvla.co/m/'
const PACK_URL = 'https://nexus.dev.services.tvla.co/v1/routine/pack'

const MAGIC_PREFIX = '133A254%7C'
const MAGIC_SUFFIX = '%7C5E34BF80'

export const MAX_ROUTINES = 25

export const QR_CODE_NAME_REQUIREMENTS =
  'QR code name must be alphanumeric, you can use _ or - in place of spaces'

function constructMagicMealId(mealId: number) {
  return `${MAGIC_PREFIX}${mealId}${MAGIC_SUFFIX}`
}

export enum Models {
  AIR = 'airvala',
  GEN2 = 'gen2',
}

export const modelInfo: {
  [key in Models]: {
    intentUrl: string
    routineKey: string
    name: string
  }
} = {
  [Models.AIR]: {
    intentUrl: 'https://api.tovala.com/v1/ovenModels/airvala/intentRoutines/',
    routineKey: 'airRoutine',
    name: 'Airvala',
  },
  [Models.GEN2]: {
    intentUrl: 'https://api.tovala.com/v1/ovenModels/gen2/intentRoutines/',
    routineKey: 'proRoutine',
    name: 'Gen 2',
  },
}

export const cookModes = [
  'idle',
  'bake',
  'broil_low',
  'broil_high',
  'airfry',
  'convection_bake',
  'steam',
  'convection_steam',
  'heat_ramp',
  'heat_ramp_gentle',
] as const
export type CookModes = (typeof cookModes)[number]

export const uiUsableCookModes: CookModes[] = [
  'idle',
  'bake',
  'broil_low',
  'broil_high',
  'airfry',
  'convection_bake',
  'steam',
]

type hexColor = `#${string}`
export const cookModeColors: { [key in CookModes]: hexColor } = {
  idle: '#f04848',
  bake: '#f0a748',
  broil_low: '#d4f048',
  broil_high: '#67f048',
  airfry: '#48f0bb',
  convection_bake: '#48b8f0',
  steam: '#f048df',
  convection_steam: '#f048df',
  heat_ramp: '#f048a7',
  heat_ramp_gentle: '#f04848',
}

export type Routine = {
  mode: CookModes
  temperature: number
  time: number
}

export type IntentRoutinePrimitive = {
  routine: Routine[]
}

export type IntentRoutine = IntentRoutinePrimitive & {
  version: string
  oven_type: string
  oven_model: string
}

export type IntentPrimitive = {
  routine: IntentRoutinePrimitive
}

export type Intent = IntentPrimitive & {
  title: string
  barcode: string
  routine: IntentRoutine
}

export function constructIntent(data: string): IntentPrimitive {
  let intent: IntentPrimitive
  try {
    intent = JSON.parse(data) as IntentPrimitive
  } catch {
    throw new Error('Invalid intent data')
  }

  if (!intent.routine) {
    throw new Error('Invalid intent data')
  }

  if (!Array.isArray(intent.routine.routine)) {
    throw new Error('Invalid intent data')
  }

  for (const routine of intent.routine.routine) {
    if (
      typeof routine.mode !== 'string' ||
      typeof routine.temperature !== 'number' ||
      typeof routine.time !== 'number'
    ) {
      throw new Error('Invalid intent data')
    }
  }

  const strippedIntent: IntentPrimitive = {
    routine: {
      routine: intent.routine.routine.map((routine) => ({
        mode: routine.mode,
        temperature: routine.temperature,
        time: routine.time,
      })),
    },
  }

  return strippedIntent
}

function getOvenIntentUrl(model: Models) {
  return modelInfo[model].intentUrl
}

function constructOvenIntentUrl(model: Models, mealId: number) {
  return `${getOvenIntentUrl(model)}${constructMagicMealId(mealId)}`
}

export async function fetchOvenIntent(
  model: Models,
  mealId: number,
): Promise<Intent> {
  const url = constructOvenIntentUrl(model, mealId)
  const response = await fetch(url)
  return response.json()
}

export type GroupedIntents = {
  [key in Models]: Intent | undefined
}

export async function fetchOvenIntents(
  mealId: number,
): Promise<GroupedIntents> {
  const airIntent = fetchOvenIntent(Models.AIR, mealId)
  const gen2Intent = fetchOvenIntent(Models.GEN2, mealId)
  const intents = await Promise.all([airIntent, gen2Intent])
  return {
    [Models.AIR]: intents.find(
      (intent) => intent.routine.oven_model === Models.AIR,
    )!,
    [Models.GEN2]: intents.find(
      (intent) => intent.routine.oven_model === Models.GEN2,
    )!,
  }
}

export type MergedIntents = {
  [key in Models]: Routine[]
}

export function mergeIntents(intents: GroupedIntents) {
  const mergedIntents: MergedIntents = {
    [Models.AIR]: intents?.[Models.AIR]?.routine?.routine ?? [],
    [Models.GEN2]: intents?.[Models.GEN2]?.routine?.routine ?? [],
  }
  return mergedIntents
}

export type PackedIntents = {
  packedRoutine: string
}

export async function packIntents(
  intents: MergedIntents,
  routineId: string,
): Promise<PackedIntents> {
  const response = await fetch(PACK_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      routineId,
      [modelInfo[Models.AIR].routineKey]: intents[Models.AIR],
      [modelInfo[Models.GEN2].routineKey]: intents[Models.GEN2],
    }),
  })
  const data = (await response.json()) as PackedIntents
  data.packedRoutine = data.packedRoutine
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
  return data
}

export function buildTvlaLookupUrl(mealId: number) {
  return `${BASE_LOOKUP_URL}${mealId}?s=A`
}

export function buildMealCodeRoutineUrl(mealId: number, packedRoutine: string) {
  return `${buildTvlaLookupUrl(mealId)}&c=${packedRoutine}`
}

export function isValidQRName(name: string) {
  return /^[a-zA-Z0-9_-]*$/.test(name)
}

export function buildRoutineQRCode(packedRoutine: string, name?: string) {
  let routine = 'ROUTINE:'

  if (name) {
    if (!isValidQRName(name)) {
      throw new Error('Invalid QR code name')
    }
    routine += `${name}:`
  }

  routine += packedRoutine

  return routine
}

export type QRCodeResponse = {
  tvlaLookupUrl: string
  mealCodeRoutineUrl: string
  routineQRCode: string
}

function buildQRCodes(
  mealId: number,
  packedRoutine: string,
  name?: string,
): QRCodeResponse {
  return {
    tvlaLookupUrl: buildTvlaLookupUrl(mealId),
    mealCodeRoutineUrl: buildMealCodeRoutineUrl(mealId, packedRoutine),
    routineQRCode: buildRoutineQRCode(packedRoutine, name),
  }
}

export type MealLookupResponse = {
  mealId: number
  routine: string
  title: string
  barcode: string
  intents: GroupedIntents
  packedIntents: PackedIntents
  qrCode: QRCodeResponse
}

export async function fetchMeal(
  mealId: number,
  routine: string = DEFAULT_ROUTINE_ID,
  name?: string,
) {
  const lookedUpMeal = await fetchOvenIntents(mealId)
  const mergedIntents = mergeIntents(lookedUpMeal)
  const packedIntents = await packIntents(mergedIntents, routine)

  const response: MealLookupResponse = {
    mealId,
    routine,
    title:
      lookedUpMeal[Models.AIR]?.title ?? lookedUpMeal[Models.GEN2]?.title ?? '',
    barcode:
      lookedUpMeal[Models.AIR]?.barcode ??
      lookedUpMeal[Models.GEN2]?.barcode ??
      '',
    intents: lookedUpMeal,
    packedIntents,
    qrCode: buildQRCodes(mealId, packedIntents.packedRoutine, name),
  }

  return response
}

// const MEAL_ID = 10963
// fetchMeal(MEAL_ID, ROUTINE_ID)
