import {
  DataWidth,
  EndianTypes,
  PackedTypes,
  Struct,
  StructDataType,
  StructOrder,
  StructToDataType,
} from './structDataTypes'

export function getPadding<T>(dataType: StructDataType<T>, size: number) {
  const padding = size % dataType.alignment
  return padding === 0 ? 0 : dataType.alignment - padding
}

export function getPaddingConditionally<T>(
  dataType: StructDataType<T>,
  size: number,
  packed: PackedTypes,
) {
  return packed === PackedTypes.PACKED ? 0 : getPadding(dataType, size)
}

export function getSize<T>(
  dataType: StructDataType<T>,
  size: number,
  packed: PackedTypes,
) {
  return dataType.size + getPaddingConditionally(dataType, size, packed)
}

export function getTotalSize<T extends Struct>(
  dataTypes: StructToDataType<T>,
  order: StructOrder<T>,
  packed: PackedTypes,
  dataWidth: DataWidth,
) {
  const sizes: number[] = []
  let size = 0
  for (const key of order) {
    const dataType = dataTypes[key as unknown as keyof typeof dataTypes]
    const dataTypeSize = getSize(
      dataType as StructDataType<unknown>,
      size,
      packed,
    )
    sizes.push(dataTypeSize)
    size += dataTypeSize
  }

  if (packed === PackedTypes.PACKED) {
    return size
  }

  const width = dataWidth as number

  if (size % width === 0) {
    return size
  }

  return size + (width - (size % width))
}

export function structify<T extends Struct>(
  uint8Arr: Uint8Array,
  fields: StructToDataType<T>,
  order: StructOrder<T>,
  endian: EndianTypes = EndianTypes.LITTLE_ENDIAN,
  packed: PackedTypes = PackedTypes.UNPACKED,
  dataWidth: DataWidth = DataWidth.WIDTH32,
) {
  const size = getTotalSize(fields, order, packed, dataWidth)
  if (uint8Arr.length < size) {
    throw new Error('Buffer is too small')
  }

  const struct: T = {} as T
  let offset = 0
  for (const key of order) {
    const field = fields[
      key as unknown as keyof typeof fields
    ] as StructDataType<unknown>
    offset += getPaddingConditionally(
      field as StructDataType<unknown>,
      offset,
      packed,
    )
    const value = field.deserialize(uint8Arr, offset, endian)
    struct[key as unknown as keyof T] = value as T[keyof T]
    offset += field.size
  }

  return struct
}

export function structifyArray<T extends Struct>(
  uint8Arr: Uint8Array,
  fields: StructToDataType<T>,
  order: StructOrder<T>,
  endian: EndianTypes = EndianTypes.LITTLE_ENDIAN,
  packed: PackedTypes = PackedTypes.UNPACKED,
  dataWidth: DataWidth = DataWidth.WIDTH32,
) {
  const structs: T[] = [] as T[]

  const totalSize = getTotalSize(fields, order, packed, dataWidth)
  if (uint8Arr.length % totalSize !== 0) {
    throw new Error('Buffer size is not a multiple of the struct')
  }

  for (let i = 0; i < uint8Arr.length; i += totalSize) {
    const newBuffer = uint8Arr.slice(i, i + totalSize)
    structs.push(structify(newBuffer, fields, order, endian, packed, dataWidth))
  }

  return structs
}

export function serialize<T extends Struct>(
  struct: T,
  fields: StructToDataType<T>,
  order: StructOrder<T>,
  uint8Arr: Uint8Array,
  endian: EndianTypes = EndianTypes.LITTLE_ENDIAN,
  packed: PackedTypes = PackedTypes.UNPACKED,
  dataWidth: DataWidth = DataWidth.WIDTH32,
) {
  const totalSize = getTotalSize(fields, order, packed, dataWidth)

  if (uint8Arr.length < totalSize) {
    throw new Error('Buffer is too small')
  }

  let offset = 0
  for (const key of order) {
    const field = fields[
      key as unknown as keyof typeof fields
    ] as StructDataType<unknown>
    offset += getPaddingConditionally(field, offset, packed)
    offset += field.serialize(struct[key as keyof T], uint8Arr, offset, endian)
  }

  if (offset < totalSize) {
    uint8Arr.fill(0, offset, totalSize)
  }
}

export function serializeArray<T extends Struct>(
  structs: T[],
  fields: StructToDataType<T>,
  order: StructOrder<T>,
  uint8Arr: Uint8Array,
  endian: EndianTypes = EndianTypes.LITTLE_ENDIAN,
  packed: PackedTypes = PackedTypes.UNPACKED,
  dataWidth: DataWidth = DataWidth.WIDTH32,
) {
  const totalSize = getTotalSize(fields, order, packed, dataWidth)

  if (uint8Arr.length < totalSize * structs.length) {
    throw new Error('Buffer is too small')
  }

  let offset = 0
  for (const struct of structs) {
    const tempBuf = new Uint8Array(totalSize)
    serialize(struct, fields, order, tempBuf, endian, packed, dataWidth)
    uint8Arr.set(tempBuf, offset)
    offset += totalSize
  }
}

export function subStructDataType<T extends Struct>(
  name: string,
  fields: StructToDataType<T>,
  order: StructOrder<T>,
  packed: PackedTypes = PackedTypes.UNPACKED,
  dataWidth: DataWidth = DataWidth.WIDTH32,
): StructDataType<T> {
  const totalSize = getTotalSize(fields, order, packed, dataWidth)

  return {
    name,
    size: totalSize,
    alignment: (
      fields[
        order[0] as unknown as keyof typeof fields
      ] as StructDataType<unknown>
    ).alignment,
    serialize: (
      value: T,
      uint8Arr: Uint8Array,
      offset: number,
      endian: EndianTypes,
    ) => {
      const newBuffer = uint8Arr.slice(offset, offset + totalSize)
      serialize(value, fields, order, newBuffer, endian, packed)
      for (let i = 0; i < newBuffer.length; i++) {
        uint8Arr[offset + i] = newBuffer[i]
      }
      return totalSize
    },
    deserialize: (
      uint8Arr: Uint8Array,
      offset: number,
      endian: EndianTypes,
    ) => {
      return structify(
        uint8Arr.slice(offset, offset + totalSize),
        fields,
        order,
        endian,
        packed,
      )
    },
  }
}
