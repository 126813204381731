function urlSafeDecode(str: string): string {
  return decodeURIComponent(str.replace(/-/g, '+').replace(/_/g, '/'))
}

function urlSafeEncode(str: string): string {
  return str.replace(/\+/g, '-').replace(/\//g, '_')
}

function decode(str: string): string {
  return atob(str)
}

function encode(str: string): string {
  return btoa(str)
}

export function decodeURLSafe(str: string): string {
  const decoded = urlSafeDecode(str)
  return decode(decoded)
}

export function encodeURLSafe(str: string): string {
  const encoded = encode(str)
  return urlSafeEncode(encoded)
}
