import { Button, Textarea } from '@tovala/component-library'
import { DownloadableQRCode } from 'components/common/DownloadableQRCode'
import Input from 'components/common/Input'
import { useState } from 'react'
import {
  buildRoutineQRCode,
  constructIntent,
  DEFAULT_ROUTINE_ID,
  fetchMeal,
  GroupedIntents,
  Intent,
  IntentPrimitive,
  isValidQRName,
  MAX_ROUTINES,
  MealLookupResponse,
  MergedIntents,
  mergeIntents,
  modelInfo,
  Models,
  packIntents,
  QR_CODE_NAME_REQUIREMENTS,
  Routine,
  uiUsableCookModes,
} from 'utils/meals'
import { convertToPrettyString } from 'utils/stringUtils'
import { isUUID } from 'utils/uuids'
import { RoutineViewer } from './RoutineViewer'
import Accordion from 'components/common/Accordion'

export function MealIdLookup() {
  const [mealId, setMealId] = useState<number>()
  const [routineId, setRoutineId] = useState<string>(DEFAULT_ROUTINE_ID)

  const [errMsg, setErrMsg] = useState<string>()

  const [meal, setMeal] = useState<MealLookupResponse>()

  async function handleFetchMeal() {
    setErrMsg('')
    setMeal(undefined)

    if (mealId === undefined) {
      setErrMsg('Please enter a meal ID')
      return
    }

    if (routineId === undefined) {
      setErrMsg('Please enter a routine ID')
      return
    }

    if (!isUUID(routineId)) {
      setErrMsg('Routine ID is not a valid UUID')
      return
    }

    try {
      const meal = await fetchMeal(mealId, routineId)
      setMeal(meal)
    } catch (e) {
      const errMsg = (e as Error).message ?? 'An error occurred'
      setErrMsg(errMsg)
    }
  }

  return (
    <div className="bg-slate-50 rounded-lg shadow-lg p-4 w-fit max-w-4xl">
      <div>
        <h1 className="text-2xl font-bold">Meal Lookup</h1>
      </div>

      <div className="flex flex-col gap-2 max-w-96">
        <div>
          <label>Meal ID:</label>
          <Input
            onChange={(e) => setMealId(Number(e.target.value))}
            type="number"
            value={mealId}
          />
        </div>

        <div>
          <label>Routine ID:</label>
          <Input
            onChange={(e) => setRoutineId(e.target.value)}
            value={routineId}
          />
        </div>

        <div>{errMsg}</div>

        <div>
          <Button onClick={handleFetchMeal} size="small">
            Fetch Meal
          </Button>
        </div>
      </div>
      {meal && (
        <div className="flex flex-col gap-4 mt-4">
          <div>
            <h2 className="text-xl font-bold">
              {meal.title} ({meal.mealId})
            </h2>
          </div>

          {/* QR Codes */}
          <div className="flex flex-row gap-4 flex-wrap">
            <div className="flex flex-col gap-2 items-center">
              <DownloadableQRCode
                caption={`${meal.mealId} MEAL`}
                downloadName={`tvlaLookupQRCode_${meal.mealId}.png`}
                value={meal.qrCode.tvlaLookupUrl}
              />
              <div>Meal QR</div>
            </div>

            <div className="flex flex-col gap-2 items-center">
              <DownloadableQRCode
                caption={`${meal.mealId} MEAL LINK`}
                downloadName={`tvlaMealQRCode_${meal.mealId}.png`}
                value={meal.qrCode.mealCodeRoutineUrl}
              />
              <div>Meal QR w/meal encoding</div>
            </div>

            <div className="flex flex-col gap-2 items-center">
              <DownloadableQRCode
                caption={`${meal.mealId} ROUTINE`}
                downloadName={`tvlaRoutineQRCode_${meal.mealId}.png`}
                value={meal.qrCode.routineQRCode}
              />
              <div>Test Routine</div>
            </div>
          </div>

          {/* Routine JSON */}
          <div>
            <h2 className="text-xl font-bold">Routine JSON</h2>
            <div className="flex flex-row gap-4">
              <div className="min-w-96">
                <div>Airvala JSON:</div>
                <Textarea
                  onChange={(e) => {
                    e.preventDefault()
                  }}
                  rows={20}
                  value={JSON.stringify(meal.intents.airvala, null, 2)}
                />
              </div>
              <div className="min-w-96">
                <div>Gen 2 JSON:</div>
                <Textarea
                  onChange={(e) => {
                    e.preventDefault()
                  }}
                  rows={20}
                  value={JSON.stringify(meal.intents.gen2, null, 2)}
                />
              </div>
            </div>
          </div>

          {/* Routine graphs ? */}
          <Accordion
            title={<h3 className="text-xl font-bold">Rendered Routines</h3>}
          >
            {meal.intents.airvala && (
              <div>
                <h3 className="text-xl font-bold">Air</h3>
                <div className="w-full h-96">
                  <RoutineViewer
                    routine={meal.intents.airvala.routine.routine}
                  />
                </div>
              </div>
            )}
            {meal.intents.gen2 && (
              <div>
                <h3 className="text-xl font-bold">Gen 2</h3>
                <div className="w-full h-96">
                  <RoutineViewer routine={meal.intents.gen2.routine.routine} />
                </div>
              </div>
            )}
          </Accordion>
        </div>
      )}
    </div>
  )
}

export function MealJsonPacker() {
  const [airJson, setAirJson] = useState<string>()
  const [gen2Json, setGen2Json] = useState<string>()

  const [routineId, setRoutineId] = useState<string>(DEFAULT_ROUTINE_ID)
  const [caption, setCaption] = useState<string>()

  const [errMsg, setErrMsg] = useState<string>()

  const [mergedIntents, setMergedIntents] = useState<MergedIntents>()
  const [routineQRCode, setRoutineQRCode] = useState<string>()

  async function handleConstruct() {
    setErrMsg('')
    setRoutineQRCode(undefined)
    setMergedIntents(undefined)

    if (routineId === undefined) {
      setErrMsg('Please enter a routine ID')
      return
    }

    if (!isUUID(routineId)) {
      setErrMsg('Routine ID is not a valid UUID')
      return
    }

    if (caption && !isValidQRName(caption)) {
      setErrMsg(QR_CODE_NAME_REQUIREMENTS)
      return
    }

    let constructedAirJson
    let constructedGen2Json

    if (airJson) {
      try {
        constructedAirJson = constructIntent(airJson)
      } catch (e) {
        setErrMsg('Failed to construct Air JSON: ' + (e as Error).message)
        return
      }
    }

    if (gen2Json) {
      try {
        constructedGen2Json = constructIntent(gen2Json)
      } catch (e) {
        setErrMsg('Failed to construct Gen2 JSON: ' + (e as Error).message)
        return
      }
    }

    if (!constructedAirJson && !constructedGen2Json) {
      setErrMsg('Please enter at least one JSON')
      return
    }

    const merged = mergeIntents({
      airvala: constructedAirJson as Intent,
      gen2: constructedGen2Json as Intent,
    })

    setMergedIntents(merged)

    const packed = await packIntents(merged, routineId)
    const routineQRCode = buildRoutineQRCode(packed.packedRoutine, caption)
    setRoutineQRCode(routineQRCode)
  }

  return (
    <div className="bg-slate-50 rounded-lg shadow-lg p-4 w-fit max-w-4xl">
      <div>
        <h1 className="text-2xl font-bold">Routine JSON Converter</h1>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex flex-row gap-2 flex-wrap">
          <div className="min-w-96">
            <label>Air JSON:</label>
            <Textarea
              onChange={(e) => setAirJson(e.target.value)}
              rows={20}
              value={airJson}
            />
          </div>

          <div className="min-w-96">
            <label>Gen2 JSON:</label>
            <Textarea
              onChange={(e) => setGen2Json(e.target.value)}
              rows={20}
              value={gen2Json}
            />
          </div>
        </div>

        <div className="max-w-96">
          <label>Routine ID:</label>
          <Input
            onChange={(e) => setRoutineId(e.target.value)}
            value={routineId}
          />
        </div>

        <div className="max-w-96">
          <label>Name:</label>
          <Input onChange={(e) => setCaption(e.target.value)} value={caption} />
        </div>

        <div>{errMsg}</div>

        <div>
          <Button onClick={handleConstruct} size="small">
            Build Meal
          </Button>
        </div>
      </div>
      {routineQRCode && (
        <div className="flex flex-col gap-4 mt-4">
          {/* QR Codes */}
          <div>
            <DownloadableQRCode
              caption={caption}
              downloadName={`tvlaRoutineQRCode.png`}
              value={routineQRCode}
            />
          </div>

          {/* Routine graph? */}
          {mergedIntents && (
            <Accordion
              title={<h3 className="text-xl font-bold">Rendered Routine</h3>}
            >
              {mergedIntents.airvala.length > 0 && (
                <div>
                  <h3 className="text-l font-bold">Air</h3>
                  <div className="w-full h-96">
                    <RoutineViewer routine={mergedIntents.airvala} />
                  </div>
                </div>
              )}
              {mergedIntents.gen2.length > 0 && (
                <div>
                  <h3 className="text-l font-bold">Gen 2</h3>
                  <div className="w-full h-96 min-w-96">
                    <RoutineViewer routine={mergedIntents.gen2} />
                  </div>
                </div>
              )}
            </Accordion>
          )}
        </div>
      )}
    </div>
  )
}

export function MealRoutineRow({
  routine,
  onDelete,
  onUpdate,
}: {
  routine: Routine
  onUpdate: (routine: Routine) => void
  onDelete: () => void
}) {
  const [mode, setMode] = useState(routine.mode)
  const [temperature, setTemperature] = useState(routine.temperature)
  const [time, setTime] = useState(routine.time)

  function handleUpdate() {
    onUpdate({ mode, temperature, time })
  }

  return (
    <div className="flex flex-row gap-2 items-center">
      <select
        className="border border-grey-3 rounded-lg px-2 py-1"
        onChange={(e) => {
          setMode(e.target.value as Routine['mode'])
        }}
        value={mode}
      >
        {uiUsableCookModes.map((option) => (
          <option key={option} value={option}>
            {convertToPrettyString(option)}
          </option>
        ))}
      </select>
      <Input
        onChange={(e) => setTemperature(Number(e.target.value))}
        placeholder="Temperature"
        rightIcon="°f"
        type="number"
        value={temperature}
      />
      <Input
        min={0}
        onChange={(e) => setTime(Number(e.target.value))}
        placeholder="Time"
        rightIcon="s"
        type="number"
        value={time}
      />
      <Button onClick={handleUpdate} size="small">
        Update
      </Button>
      <Button onClick={onDelete} size="small">
        Delete
      </Button>
    </div>
  )
}

export function MealRoutineCreator() {
  const [model, setModel] = useState<Models>()
  const [intent, setIntent] = useState<IntentPrimitive>({
    routine: { routine: [] },
  })

  const [caption, setCaption] = useState<string>()

  const [errMsg, setErrMsg] = useState<string>()

  const [routineQRCode, setRoutineQRCode] = useState<string>()
  const [hasQRCodeEverBeenGenerated, setHasQRCodeEverBeenGenerated] =
    useState<boolean>(false)

  async function updateQRCode(
    intent: IntentPrimitive,
    model: Models | undefined,
  ) {
    setErrMsg('')
    setRoutineQRCode(undefined)

    if (!model) {
      setErrMsg('Please select a model')
      return
    }

    if (intent.routine.routine.length === 0) {
      setErrMsg('Please add at least one row')
      return
    }

    if (caption && !isValidQRName(caption)) {
      setErrMsg(QR_CODE_NAME_REQUIREMENTS)
      return
    }

    const merged = mergeIntents({
      [model]: intent,
    } as GroupedIntents)
    const packed = await packIntents(merged, DEFAULT_ROUTINE_ID)
    const routineQRCode = buildRoutineQRCode(packed.packedRoutine, caption)
    setRoutineQRCode(routineQRCode)
    if (!hasQRCodeEverBeenGenerated) {
      setHasQRCodeEverBeenGenerated(true)
    }
  }

  function addRow() {
    if (intent.routine.routine.length >= MAX_ROUTINES) {
      setErrMsg(`Max routines reached: ${MAX_ROUTINES}`)
      return
    }

    setIntent((prev) => {
      const intent: IntentPrimitive = {
        routine: {
          routine: [
            ...prev.routine.routine,
            {
              mode:
                prev.routine.routine?.[prev.routine.routine.length - 1]?.mode ??
                'idle',
              temperature:
                prev.routine.routine?.[prev.routine.routine.length - 1]
                  ?.temperature ?? 0,
              time:
                prev.routine.routine?.[prev.routine.routine.length - 1]?.time ??
                0,
            },
          ],
        },
      }
      updateQRCode(intent, model)
      return intent
    })
  }

  function deleteRow(idx: number) {
    setIntent((prev) => {
      const routine = [...prev.routine.routine]
      routine.splice(idx, 1)
      const intent: IntentPrimitive = { routine: { routine } }
      updateQRCode(intent, model)
      return intent
    })
  }

  function updateRow(idx: number, routine: Routine) {
    setIntent((prev) => {
      const newRoutine = [...prev.routine.routine]
      newRoutine[idx] = routine
      const intent: IntentPrimitive = { routine: { routine: newRoutine } }
      updateQRCode(intent, model)
      return intent
    })
  }

  return (
    <div className="bg-slate-50 rounded-lg shadow-lg p-4">
      <div className="flex flex-row gap-4 flex-wrap justify-start">
        <div>
          <div>
            <h1 className="text-2xl font-bold">Routine Creator</h1>
          </div>

          <div>
            <select
              className="border border-grey-3 rounded-lg px-2 py-1"
              onChange={(e) => {
                setModel(e.target.value as Models)
                setIntent({ routine: { routine: [] } })
              }}
              value={model}
            >
              <option disabled selected value="">
                Select Model
              </option>
              {Object.entries(modelInfo).map(([key, value]) => (
                <option key={key} value={key}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col gap-2">
            <div>
              {intent.routine.routine.map((routine, idx) => (
                <MealRoutineRow
                  key={idx}
                  onDelete={() => deleteRow(idx)}
                  onUpdate={(routine) => updateRow(idx, routine)}
                  routine={routine}
                />
              ))}
            </div>

            <div>
              <Button onClick={addRow} size="small">
                Add Row
              </Button>
            </div>

            <div className="max-w-96">
              <label>Name:</label>
              <Input
                onBlur={() => {
                  updateQRCode(intent, model)
                }}
                onChange={(e) => setCaption(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.currentTarget.blur()
                  }
                }}
                value={caption}
              />
            </div>
          </div>

          <div>{errMsg}</div>

          <div>
            <div className="flex flex-col gap-4 mt-4">
              {routineQRCode && (
                <DownloadableQRCode
                  caption={caption}
                  downloadName={`tvlaRoutineQRCode.png`}
                  value={routineQRCode}
                />
              )}
              {!routineQRCode &&
                hasQRCodeEverBeenGenerated &&
                intent.routine.routine.length > 0 && (
                  <div className="p-[27px]">
                    {/* Skeleton UI, grid of black dots, prevents as harsh of a pop-in when transitioning between 2 QRCodes */}
                    <div
                      className="w-[228px] h-[228px]"
                      style={{
                        backgroundImage:
                          'linear-gradient(45deg, #000 26%, transparent 26%), linear-gradient(135deg, #000 26%, transparent 26%), linear-gradient(45deg, transparent 75%, #000 75%), linear-gradient(135deg, transparent 75%, #000 75%)',
                        backgroundSize: '20px 20px',
                        backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0',
                      }}
                    ></div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="flex-1 min-w-96">
          {intent.routine.routine.length > 0 && (
            <div>
              <h3 className="text-xl font-bold">Rendered Routine</h3>
              <div className="w-full h-96">
                <RoutineViewer routine={intent.routine.routine} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
