import { useEffect, useState } from 'react'

export type WebsocketHookOptions<T> = {
  enabled?: boolean
  onConnectData?: () => T
  onDisconnectData?: () => T
  clearDataOnConnect?: boolean
}

const sockets = {} as { [key: string]: WebSocket }

function useWebsocket<T>(
  key: string,
  url: string,
  parser: (data: string) => T[],
  options?: WebsocketHookOptions<T>,
) {
  const [data, setData] = useState<T[]>([])
  const [reconnect, setReconnect] = useState(true)
  const [connected, setConnected] = useState(false)

  const disconnect = () => {
    const ws = sockets[key]

    setConnected(false)
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.close()
      delete sockets[url]
    }
  }

  useEffect(() => {
    function connect() {
      const ws = sockets[key]

      if (!options?.enabled || !reconnect) {
        return
      }

      if (
        ws &&
        (ws.readyState === WebSocket.OPEN ||
          ws.readyState === WebSocket.CONNECTING)
      ) {
        return
      }

      const socket = (sockets[key] = new WebSocket(url))

      socket.onmessage = (event) => {
        try {
          const parsedData = parser(event.data)
          setData((prevData) => [...(prevData ?? []), ...parsedData])
        } catch (error) {
          console.error('Failed to parse data:', error)
        }
      }

      socket.onopen = () => {
        if (options?.clearDataOnConnect) {
          setData([])
        }
        if (options?.onConnectData) {
          setData((prevData) => [...(prevData ?? []), options.onConnectData!()])
        }
        setConnected(true)
      }

      socket.onclose = () => {
        if (options?.onDisconnectData) {
          setData((prevData) => [
            ...(prevData ?? []),
            options.onDisconnectData!(),
          ])
        }
        setConnected(false)
        setTimeout(connect, 1000)
      }
    }

    connect()

    return () => {
      disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, parser, reconnect, options?.clearDataOnConnect])

  return {
    data,
    setData,
    setReconnect,
    connected,
    disconnect,
  }
}

export default useWebsocket
