import { Button, Combobox, Textarea } from '@tovala/component-library'
import { DownloadableQRCode } from 'components/common/DownloadableQRCode'
import { InputHTMLAttributes, SelectHTMLAttributes, useState } from 'react'

type TestCodeArg = {
  name: string
  prettyName: string
}

type TestCodeInputArg = TestCodeArg & {
  type: 'string' | 'number'
  params?: InputHTMLAttributes<HTMLTextAreaElement>
}

type TestCodeOptionArg = TestCodeArg & {
  type: 'option'
  options: { value: string; label: string }[]
  params?: SelectHTMLAttributes<HTMLSelectElement>
}

const TEST_CODES: {
  [key: string]: {
    prettyName: string
    description: string
    args: (TestCodeInputArg | TestCodeOptionArg)[]
    assembler: (args: string[]) => string
  }
} = {
  selfTest: {
    prettyName: 'Self Test',
    description: 'Run a self test on the oven',
    args: [
      {
        type: 'option',
        name: 'testNumber',
        prettyName: 'Test',
        options: [
          { value: '1', label: 'Display' },
          { value: '2', label: 'Midline' },
          {
            value: '3',
            label: 'Auto-Midline',
          },
          {
            value: '4',
            label: 'Auto-MidlineV2',
          },
        ],
      },
      {
        type: 'number',
        name: 'testNumber2',
        prettyName: 'Duration',
        params: { min: 0, max: 255 },
      },
    ],
    assembler: (args) => `TEST:selfTest:${args.join(':')}`,
  },
  buzzer: {
    prettyName: 'Buzzer',
    description: 'Make the oven buzz',
    args: [
      {
        type: 'option',
        name: 'buzzNumber',
        prettyName: 'Note',
        options: [
          {
            value: '0',
            label: 'A',
          },
          {
            value: '1',
            label: 'A#',
          },
          {
            value: '2',
            label: 'B',
          },
          {
            value: '3',
            label: 'C',
          },
          {
            value: '4',
            label: 'C#',
          },
          {
            value: '5',
            label: 'D',
          },
          {
            value: '6',
            label: 'D#',
          },
          {
            value: '7',
            label: 'E',
          },
          {
            value: '8',
            label: 'F',
          },
          {
            value: '9',
            label: 'F#',
          },
          {
            value: '10',
            label: 'G',
          },
          {
            value: '11',
            label: 'G#',
          },
        ],
      },
      {
        type: 'number',
        name: 'buzzNumber2',
        prettyName: 'Octave',
        params: {
          min: 0,
          max: 7,
        },
      },
      {
        type: 'number',
        name: 'buzzNumber3',
        prettyName: 'Duration',
        params: {
          min: 0,
          max: 255,
        },
      },
      {
        type: 'number',
        name: 'buzzNumber4',
        prettyName: 'Decay',
        params: {
          min: 0,
          max: 255,
        },
      },
    ],
    assembler: (args) => `TEST:buzzer:${args.join(':')}`,
  },
  output: {
    prettyName: 'Output',
    description: 'Set GPIO output',
    args: [
      {
        type: 'option',
        name: 'outputNumber',
        prettyName: 'GPIO',
        options: [
          // top
          // bottom
          // lamp
          // convection
          // coolingFan
          // broiler
          // coolingFan2
          // convectionHi
          // boiler
          // solenoid
          // drain
          {
            value: 'top',
            label: 'Top',
          },
          {
            value: 'bottom',
            label: 'Bottom',
          },
          {
            value: 'lamp',
            label: 'Lamp',
          },
          {
            value: 'convection',
            label: 'Convection',
          },
          {
            value: 'coolingFan',
            label: 'Cooling Fan',
          },
          {
            value: 'broiler',
            label: 'Broiler',
          },
          {
            value: 'coolingFan2',
            label: 'Cooling Fan 2',
          },
          {
            value: 'convectionHi',
            label: 'Convection Hi',
          },
          {
            value: 'boiler',
            label: 'Boiler',
          },
          {
            value: 'solenoid',
            label: 'Solenoid',
          },
          {
            value: 'drain',
            label: 'Drain',
          },
        ],
      },
      {
        type: 'option',
        name: 'outputNumber2',
        prettyName: 'GPIO State',
        options: [
          {
            value: '0',
            label: 'Off',
          },
          {
            value: '1',
            label: 'On',
          },
        ],
      },
      {
        type: 'number',
        name: 'outputNumber3',
        prettyName: 'Timeout',
        params: {
          min: 0,
          max: 255,
        },
      },
    ],
    assembler: (args) => `TEST:output:${args.join(':')}`,
  },
  coolingFan: {
    prettyName: 'Cooling Fan',
    description: 'Turn the cooling fan on',
    args: [],
    assembler: () => 'TEST:coolingFan',
  },
  tempProbeErrorDisable: {
    prettyName: 'Temp Probe Error Disable',
    description: 'Disable the temperature probe error',
    args: [],
    assembler: () => 'TEST:tempProbe',
  },
  HWID: {
    prettyName: 'Get HWID',
    description: 'Get the hardware ID',
    args: [],
    assembler: () => 'TEST:HWID',
  },
  FWSHA: {
    prettyName: 'Get FW SHA',
    description: 'Get the firmware SHA',
    args: [],
    assembler: () => 'TEST:FWSHA',
  },
  IDENT: {
    prettyName: 'Identify',
    description: 'Identify the oven',
    args: [{ type: 'string', name: 'ident', prettyName: 'Identifier' }],
    assembler: (args) => `TEST:IDENT:${args?.[0] ?? ''}`,
  },
  FWVER: {
    prettyName: 'FW Version',
    description: 'Get the firmware version',
    args: [],
    assembler: () => 'TEST:FWVER',
  },
  MANUAL: {
    prettyName: 'Manual',
    description: 'Run a manual test',
    args: [
      {
        type: 'string',
        name: 'manual',
        prettyName: 'Manual',
        params: { maxLength: 1024 },
      },
    ],
    assembler: (args) => args?.[0] ?? '',
  },
  WiFi: {
    prettyName: 'WiFi',
    description: 'Connect to WiFi',
    args: [
      {
        type: 'string',
        name: 'ssid',
        prettyName: 'SSID (Network Name)',
        params: { maxLength: 32 },
      },
      {
        type: 'string',
        name: 'password',
        prettyName: 'Password',
        params: { maxLength: 64 },
      },
    ],
    assembler: (args) =>
      `WIFI:S:${args?.[0] ?? ''};T:WPA;P:${args?.[1] ?? ''};;`,
  },
}

type TestCode = keyof typeof TEST_CODES

const TestCodes = () => {
  const [selectedCode, setSelectedCode] = useState<TestCode>()
  const [args, setArgs] = useState<string[]>([])
  const [showCaption, setShowCaption] = useState(false)

  const testCode =
    selectedCode &&
    args.filter((arg) => arg && arg.length > 0).length ===
      TEST_CODES[selectedCode].args.length
      ? TEST_CODES[selectedCode].assembler(args.map((arg) => arg ?? ''))
      : null

  return (
    <div className="p-4 bg-slate-50 drop-shadow-lg min-w-64 max-w-96 rounded-lg">
      <h2 className="text-xl font-bold">Test Codes</h2>
      <div className="space-y-4">
        <Combobox
          onChange={(e) => {
            if (!e?.value) {
              setArgs([])
              setSelectedCode(undefined)
              return
            }
            setArgs(new Array(TEST_CODES[e.value as TestCode].args.length))
            setSelectedCode(e.value as TestCode)
          }}
          options={Object.keys(TEST_CODES).map((code) => ({
            value: code,
            label: TEST_CODES[code as TestCode].prettyName,
          }))}
          value={{
            value: selectedCode ?? '',
            label: selectedCode
              ? TEST_CODES?.[selectedCode]?.prettyName
              : 'Select a test code',
          }}
        ></Combobox>
        {selectedCode && (
          <>
            <p>{TEST_CODES[selectedCode].description}</p>
            <div className="flex flex-col gap-2">
              {TEST_CODES[selectedCode].args.map((arg, indx) => (
                <div key={arg.name}>
                  <label htmlFor={arg.name}>{arg.prettyName}</label>
                  {arg.type === 'option' && (
                    <Combobox
                      {...arg.params}
                      onChange={(e) => {
                        if (!e) {
                          return
                        }
                        console.log(e)
                        args[indx] = e?.value
                        setArgs([...args])
                      }}
                      options={arg.options}
                      value={
                        args[indx]
                          ? {
                              value: args[indx],
                              label:
                                arg.options.find(
                                  (opt) => opt.value === args[indx],
                                )?.label || 'Please select an option',
                            }
                          : { value: '', label: 'Select an option' }
                      }
                    ></Combobox>
                  )}
                  {(arg.type === 'string' || arg.type === 'number') && (
                    <Textarea
                      {...arg.params}
                      id={arg.name}
                      onChange={(e) => {
                        args[indx] = e.target?.value
                        if (arg.type === 'number' && !args[indx]) {
                          args[indx] = '0'
                        }
                        setArgs([...args])
                      }}
                      rows={4}
                      value={args[indx]}
                    ></Textarea>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {selectedCode && testCode && (
        <div className="mt-4 flex flex-col items-center gap-4">
          <div className="text-ellipsis w-full overflow-clip text-center">
            {testCode}
          </div>
          <DownloadableQRCode
            caption={showCaption ? testCode : ''}
            value={testCode}
          />
          <div>
            <Button onClick={() => setShowCaption(!showCaption)} size="small">
              {showCaption ? 'Hide' : 'Show'} Caption
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default TestCodes
