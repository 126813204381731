import { Tooltip } from '@tovala/component-library'
import { ReactNode } from 'react'

type Column = {
  key: string
  title?: ReactNode
  padding?: string
  width?: string
  tooltip?: boolean
}

type RowData = {
  [key: string]: ReactNode
}

type TableProps = {
  columns: Column[]
  data: RowData[]
  onRowClick?: (index: number, row: RowData) => void
  children?: ReactNode
  filters?: { [key: string]: ReactNode }
}

const Table = ({
  columns,
  data,
  onRowClick,
  children,
  filters,
}: TableProps) => {
  const footer = children ? children : <></>

  const TableRow = ({ index, row }: { index: number; row: RowData }) => {
    const TableColumn = ({
      column,
      columnIndex,
    }: {
      column: Column
      columnIndex: number
    }) => (
      <td
        key={columnIndex}
        className={`border-gray-200 py-2 ${column.padding || 'px-4'} ${column.tooltip || onRowClick ? 'cursor-pointer' : ''}`}
        onClick={() =>
          !column.tooltip && onRowClick && onRowClick(columnIndex, row)
        }
      >
        <div className={`truncate ${column.width ? column.width : ''}`}>
          {column.tooltip ? (
            <Tooltip
              trigger={<div className="truncate">{row[column.key]}</div>}
            >
              {row[column.key]}
            </Tooltip>
          ) : (
            row[column.key]
          )}
        </div>
      </td>
    )

    return (
      <tr key={index} className={index % 2 === 0 ? ' bg-gray-50' : ' bg-white'}>
        {columns.map((column, columnIndex) => (
          <TableColumn
            key={columnIndex}
            column={column}
            columnIndex={columnIndex}
          />
        ))}
      </tr>
    )
  }

  return (
    <div className="overflow-clip rounded-lg">
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          <thead className="bg-orange-1 text-white">
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  className={`p-2 text-md truncate ${column.width ? column.width : ''}`}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          {filters && (
            <tr>
              {columns.map((column) => (
                <td key={column.key} className={column.padding || 'px-4'}>
                  {filters[column.key] || ''}
                </td>
              ))}
            </tr>
          )}
          <tbody>
            {data.map((row, index) => (
              <TableRow key={index} index={index} row={row} />
            ))}
          </tbody>
        </table>
      </div>
      {footer}
    </div>
  )
}

export default Table
