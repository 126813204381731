import { useQuery } from '@tanstack/react-query'
import { Modal, ModalHeader } from '@tovala/component-library'
import JSONTable from 'components/common/JSONTable'
import PaginationTableFooter from 'components/common/PaginationTableFooter'
import Table from 'components/common/Table'
import { useState } from 'react'
import {
  fetchJobDetails,
  fetchJobsByThing,
  JobData,
  JobProcessDetails,
  JSONObject,
  getAuthToken,
} from 'utils/oatsApi'

type Props = {
  ovenId: string
}

const ThingJobHistory = ({ ovenId }: Props) => {
  const [historyPage, setHistoryPage] = useState(0)
  const [historyLimit, setHistoryLimit] = useState(10)

  const [selectedJob, setSelectedJob] = useState<string | null>(null)

  const {
    data: historyJobs,
    isLoading: historyLoading,
    error: historyError,
    isError: isHistoryError,
    isPreviousData: isHistoryPreviousData,
  } = useQuery({
    queryKey: ['thingJobHistory', ovenId, historyPage, historyLimit],
    queryFn: async () => {
      return await fetchJobsByThing(ovenId, historyPage, historyLimit)
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  const { data: jobDetails, isPreviousData: isJobDetailsPreviousData } =
    useQuery({
      queryKey: ['jobDetails', selectedJob],
      queryFn: async () => {
        const bearerToken = getAuthToken()
        return await fetchJobDetails(bearerToken, selectedJob!)
      },
      enabled: !!selectedJob,
      keepPreviousData: true,
      cacheTime: 1000 * 60 * 5,
      staleTime: 1000 * 60,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: 0,
    })

  return (
    <div>
      <div
        className={
          'relative overflow-x-hidden rounded-lg shadow-lg p-4 mb-4 max-w-[80vw] xl:max-h-screen sm:max-w-96'
        }
      >
        <h2 className="text-xl font-bold">Job History</h2>
        <div>
          <Table
            columns={[
              {
                title: 'Status',
                key: 'status',
                tooltip: true,
              },
              {
                title: 'Job Last Updated',
                key: 'last_updated',
              },
              {
                title: 'Job',
                key: 'job_id',
              },
            ]}
            data={
              historyJobs?.map((job) => ({
                status: job.job_execution_summary.status,
                last_updated: new Date(Date.parse(job.job_execution_summary.lastUpdatedAt)).toLocaleString(),
                job_id: job.job_id,
              })) ?? []
            }
            onRowClick={(_, row) => {
              setSelectedJob(row?.job_id as string | null)
            }}
          >
            <PaginationTableFooter
              isLoading={isHistoryPreviousData}
              onChangePage={setHistoryPage}
              onChangeRowsPerPage={setHistoryLimit}
              page={historyPage}
              rowsPerPage={historyLimit}
              total={historyJobs?.length || 0}
              totalPages={Math.ceil((historyJobs?.length || 0) / historyLimit)}
            >
              {historyLoading && (
                <div className="text-center text-gray-500 p-4 flex-1">
                  Loading...
                </div>
              )}

              {isHistoryError && (
                <div className="text-center text-red-500 p-4 flex-1">
                  Error: {(historyError as Error)?.message ?? historyError}
                </div>
              )}

              {historyJobs?.length === 0 && (
                <div className="text-center text-gray-500 p-4 flex-1">
                  No results found
                </div>
              )}
            </PaginationTableFooter>
          </Table>
        </div>
      </div>
      {jobDetails && !isJobDetailsPreviousData && (
        <JobDetailsModal
          jobDetails={jobDetails}
          setSelectedJob={setSelectedJob}
        />
      )}
    </div>
  )
}

const JobDetailsModal = ({
  setSelectedJob,
  jobDetails,
}: {
  setSelectedJob: (jobId: string | null) => void
  jobDetails: JobData
}) => {
  return (
    <Modal
      onCloseModal={() => {
        setSelectedJob(null)
      }}
    >
      <ModalHeader
        onClickClose={() => {
          setSelectedJob(null)
        }}
      >
        <span className="text-xl font-bold">Job Details</span>
      </ModalHeader>
      <div className="m-4">
        <div>
          <div>
            <JSONTable
              data={
                {
                  ...jobDetails,
                  job_process_details: {
                    ...jobDetails.job_process_details,
                    total_things: Object.keys(
                      jobDetails.job_process_details,
                    ).reduce(
                      (acc, key) =>
                        acc +
                        jobDetails.job_process_details[
                          key as keyof JobProcessDetails
                        ],
                      0,
                    ),
                  },
                } as JSONObject
              }
            />
          </div>
          <div className="flex justify-end mt-2">
            <button
              className="text-white px-4 py-2 rounded-lg font-bold bg-orange-1"
              onClick={() => {
                setSelectedJob(null)
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ThingJobHistory
