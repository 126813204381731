import { QRCodeCanvas } from 'qrcode.react'
import { ComponentProps, useEffect, useId, useState } from 'react'

type Props = ComponentProps<typeof QRCodeCanvas> & {
  downloadName?: string
  caption?: string
}

export function DownloadableQRCode({ downloadName, caption, ...props }: Props) {
  const id = useId()

  const [scale, setScale] = useState<number>(1)

  // This is a hack get the scale factor of the canvas
  useEffect(() => {
    if (!caption) {
      return
    }

    const canvas = document.getElementById(id) as HTMLCanvasElement
    if (!canvas) {
      return
    }
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      return
    }

    const originalScale = ctx.scale
    ctx.scale = (x: number, y: number) => {
      originalScale.call(ctx, x, y)
      console.log('scale', x, y)
      setScale(x)
    }
  }, [caption, id, props])

  // This is a hack to scale the text on the canvas
  // Done in a separate useEffect because we want to ensure that the text is drawn long after the scale is set
  // And the scale is set before the QR code is fully rendered
  useEffect(() => {
    if (!caption) {
      return
    }

    const canvas = document.getElementById(id) as HTMLCanvasElement
    if (!canvas) {
      return
    }
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      return
    }

    const width = canvas.width / scale
    const height = canvas.height / scale

    let fontSize = 4
    ctx.font = `${fontSize}px sans-serif`

    const textSize = ctx.measureText(caption)
    let textWidth = textSize.width
    const textHeight =
      textSize.actualBoundingBoxAscent + textSize.actualBoundingBoxDescent

    ctx.fillStyle = 'white'
    ctx.fillRect(0, height - 1, width, textHeight + 1)

    // We dont do this if the text is within the width of the canvas as
    // scaling up the text will make it overlap the QR code
    if (textWidth > width) {
      fontSize = (width / textWidth) * fontSize
      ctx.font = `${fontSize}px sans-serif`

      const textSize = ctx.measureText(caption)
      textWidth = textSize.width
    }

    ctx.fillStyle = 'black'
    ctx.fillText(caption, (width - textWidth) / 2, height - 0.5)
  }, [scale, caption, id, props])

  return (
    <div className="w-fit h-fit">
      <a
        aria-label="Download QR Code"
        className="cursor-pointer select-none"
        onClick={(e) => {
          e.preventDefault()

          const canvas = document.getElementById(id) as HTMLCanvasElement

          const filename = downloadName || 'qr-code'

          const dataUrl = canvas.toDataURL('image/png')
          const a = document.createElement('a')
          a.href = dataUrl
          a.download = filename
          a.click()
        }}
      >
        <QRCodeCanvas
          id={id}
          includeMargin={true}
          level="L"
          size={256}
          title="test"
          {...props}
        />
      </a>
    </div>
  )
}
