import React, { Children, isValidElement, cloneElement } from 'react'

type MenuItem = {
  key: string
  label: string
  onClick: () => void
  disabled?: boolean
  icon?: React.ReactElement
  styles?: string
}

type Props = {
  children: React.ReactElement<MenuItem> | React.ReactElement<MenuItem>[]
}

const DropdownMenu = ({ children }: Props) => {
  return (
    <div className="bg-slate-50 shadow-lg rounded-lg border p-2 min-w-44">
      {Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            key: String(index),
          })
        }
      })}
    </div>
  )
}

const MenuItem = ({ label, onClick, disabled, icon, styles }: MenuItem) => {
  return (
    <button
      className="flex items-center w-full px-4 py-2 text-left hover:text-orange-1"
      disabled={disabled}
      onClick={onClick}
    >
      {icon && <div className="mr-2 w-4 h-4">{icon}</div>}
      <span className={styles || ''}>{label}</span>
    </button>
  )
}

const Divider = () => {
  return <div className="h-px bg-k/8_110 bg-slate-300" />
}

export { DropdownMenu, MenuItem, Divider }
