import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from 'contexts/auth'
import { getEnvVar } from 'utils/env'

import { LogoLockUpIcon } from './Icons'

const HeaderJustLogo = ({
  logoWrapper = undefined,
}: {
  logoWrapper?: React.FC<{ children: ReactNode }>
}) => {
  const { isLoggedIn } = useAuth()

  let logo = (
    <div className="h-4 w-32 text-black">
      <LogoLockUpIcon isProduction={getEnvVar('APP_ENV') === 'production'} />
    </div>
  )

  if (logoWrapper) {
    const Wrapper = logoWrapper

    logo = <Wrapper>{logo}</Wrapper>
  }

  return (
    <header className="flex justify-between border-b border-grey-3 px-16 py-8 md:px-4 md:py-6">
      <div>{logo}</div>

      {isLoggedIn && (
        <Link className="text-orange-1 underline" to="/logout">
          Logout
        </Link>
      )}
    </header>
  )
}

export default HeaderJustLogo
