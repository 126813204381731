import {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
  ReactNode,
} from 'react'

import { useParams, useNavigate } from 'react-router-dom'
import {
  setName,
  setFavorite,
  useOvenDetails,
  useNamedDevices,
  markViewed,
  useFavorites,
  OvenData,
} from 'utils/oatsApi'
import { getEnvVar } from 'utils/env'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import OvenEventViewer from './OvenEventViewer'
import OvenCommandsExecutor from './OvenCommands'
import OvenLogs from './OvenLogs'
import ConnectionState from './ConnectionState'
import ThingJobHistory from './OvenJobsViewer'
import {
  Modal,
  ModalHeader,
  StarIcon,
  Tooltip,
} from '@tovala/component-library'
import EditThingsModal from './EditThingsModal'
import ImagePublish from './ImagePublish'
import {
  CloudOutlineIcon,
  FlameIcon,
  HardwareChipOutlineIcon,
  OTAPublishIcon,
} from 'components/common/Icons'

const useHash = (): [string, (key?: string) => void] => {
  const [hash, setHash] = useState(() => window.location.hash)

  const updateHash = useCallback((newHash?: string) => {
    if (newHash) {
      window.location.hash = newHash
    } else {
      history.replaceState('', document.title, window.location.pathname)
      window.dispatchEvent(new HashChangeEvent('hashchange'))
    }
  }, [])

  useEffect(() => {
    const handleHashChange = () => {
      setHash(window.location.hash)
    }

    window.addEventListener('hashchange', handleHashChange)

    return () => {
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [])

  return [hash, updateHash]
}

const PreOvenPage = () => {
  const { ovenId } = useParams()

  if (!ovenId) {
    return <></>
  }

  return <OvenPage ovenId={ovenId} />
}

const Link = ({
  classNames,
  children,
  ...rest
}: {
  classNames?: string
  children: ReactNode
  [x: string]: unknown
}) => (
  <a
    className={`text-blue-400 cursor-pointer hover:underline ${classNames}`}
    {...rest}
  >
    {children}
  </a>
)

const OvenPage = ({ ovenId }: { ovenId: string }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const popups = ['#edit', '#ota', '#logs']
  const [popup, setPopup] = useHash()

  const editOpen = popup === '#edit'
  const otaOpen = popup === '#ota'

  if (popup && !popups.includes(popup)) {
    setPopup()
  }

  const [editingName, setEditingName] = useState(false)

  const { data } = useOvenDetails(ovenId)
  const oven = (data || {}) as OvenData

  const { data: namedDevices, isLoading: namedDevicesLoading } =
    useNamedDevices()

  const ovenNames = namedDevices?.ovenNames || {}

  const [ovenName, setOvenName] = useState<string | undefined>(undefined)

  const { data: favorites, isFetching: favoritesLoading } = useFavorites()

  const myFavorite = (favorites || []).find((f) => f.deviceID === ovenId)

  useQuery(['viewed', ovenId], async () => {
    await markViewed(ovenId)
    queryClient.refetchQueries(['device-history'])
    return []
  })

  const { isFetching: isSavingFavorites, refetch: toggleFavorite } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['favorite', ovenId],
    queryFn: async () => {
      await setFavorite(ovenId, !myFavorite)
      queryClient.refetchQueries(['favorites'])
      return []
    },
    enabled: false,
  })

  const { isFetching: isUpdatingName, refetch: updateNameCb } = useQuery({
    queryKey: ['name', ovenId, ovenName],
    queryFn: async () => {
      await setName(ovenId, ovenName || '')
      queryClient.refetchQueries(['device-names'])
      setEditingName(false)
      return []
    },
    enabled: false,
  })

  const givenName = ovenNames[ovenId]
  const updateName = useMemo(() => updateNameCb, [updateNameCb])

  useEffect(() => {
    if (
      !namedDevicesLoading &&
      ovenName !== undefined &&
      ovenName !== givenName
    ) {
      updateName()
    }
  }, [ovenName, namedDevicesLoading, givenName, updateName])

  useEffect(() => {
    setOvenName(givenName)
  }, [givenName])

  const nameRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const nameInput = nameRef.current as HTMLInputElement
    if (nameInput) {
      nameInput.focus()
      nameInput.select()
    }
  }, [editingName])

  const EditNameForm = () => (
    <form
      className="flex gap-2"
      onSubmit={async (ev) => {
        ev.preventDefault()
        const nameField = ev.currentTarget.elements.namedItem(
          'ovenName',
        ) as HTMLInputElement
        setOvenName(nameField.value)
      }}
    >
      <div className="flex items-center gap-4 text-nowrap">
        {editingName ? (
          <input
            ref={nameRef}
            className="box-content border-grey-4 rounded-lg border px-4"
            defaultValue={ovenName}
            name="ovenName"
            onKeyDown={(ev) => ev.key === 'Escape' && setEditingName(false)}
          />
        ) : (
          ovenName && <h1 className="px-4 border rounded-lg">{ovenName}</h1>
        )}
        {!editingName && (
          <button
            className="h-8 lg:h-6 rounded-full bg-orange-1 text-white opacity-80 hover:opacity-100 active:text-grey-1 disabled:bg-grey-7 disabled:text-grey-2 transition-colors px-6 lg:px-4 text-sm"
            onClick={(ev) => {
              ev.preventDefault()
              setEditingName(!editingName)
            }}
          >
            {ovenName ? 'Edit Name' : 'Assign Name'}
          </button>
        )}
      </div>
    </form>
  )

  const ThingGroupList = () => {
    const groups = oven.thingGroupNames || []

    return (
      <div className="glg:flex items-center">
        <span className="font-bold px-2 text-nowrap">Thing Groups:</span>

        <span className="md:flex md:flex-wrap items-center">
          {Array.from(groups.entries()).map(([index, group]) => {
            return (
              <span key={index} className="p-2">
                <Link onClick={() => navigate(`/?tab=search&search=${group}`)}>
                  {group}
                </Link>
              </span>
            )
          })}
          <a
            className="px-2 cursor-pointer rounded-lg border-2 bg-green-901 hover:bg-red-800 hover:border-green-902"
            onClick={() => setPopup('edit')}
          >
            edit...
          </a>
        </span>
      </div>
    )
  }

  const BasicDetails = () => {
    return (
      <div className="shadow-lg rounded-lg border border-gray-200 p-2 flex flex-wrap gap-2">
        <div>
          <span className="font-bold p-2">State:</span>
          <span>{oven.state}</span>
        </div>
        <div className="pr-2 sm:px-0">
          <span className="font-bold p-2">Model:</span>
          <span>
            {
              <Link
                onClick={() =>
                  navigate(
                    `/?tab=search&search=${oven.attributes?.deviceModel}`,
                  )
                }
              >
                {oven.attributes?.deviceModel}
              </Link>
            }
          </span>
        </div>

        <div className="pr-2 sm:px-0">
          <span className="font-bold p-2">Owner:</span>
          {oven.userid ? (
            <Link href={`${getEnvVar('GLAZE_URL')}/user/${oven.userid}`}>
              {oven.userid} / {oven.email}
            </Link>
          ) : (
            <span>Unknown</span>
          )}
        </div>

        <div className="pr-2 sm:px-0">
          <span className="font-bold p-2">FW Version:</span>
          <span>
            {
              <Link
                onClick={() =>
                  navigate(`/?tab=search&search=${oven.firmwareVersion}`)
                }
              >
                {oven.firmwareVersion}
              </Link>
            }
          </span>
        </div>
        <div className="glg:flex justify-evenly">
          {oven.attributes?.configGroup && (
            <div className="lg:w-full pr-2">
              <span className="font-bold p-2 text-nowrap">Config Group:</span>
              <Link
                onClick={() =>
                  navigate(
                    `/?tab=search&search=${oven.attributes?.configGroup}`,
                  )
                }
              >
                {oven.attributes?.configGroup}
              </Link>
            </div>
          )}
          <div className="lg:w-full">
            <span className="font-bold p-2 nowrap">Config Version:</span>
            <span>{oven.configVersion}</span>
          </div>
        </div>
        <div className="w-full">
          <ThingGroupList />
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-6">
      <div className="gmd:flex gap-5 justify-between items-center bg-white border-2 p-2 shadow-lg rounded-lg">
        <div className="flex flex-grow font-medium md:text-k/36_110 gap-2">
          <div className={namedDevicesLoading ? 'opacity-20' : ''}>
            <div>
              <div className="flex items-center text-wrap">
                <span className="text-2xl lg:text-lg">
                  {oven.attributes?.serialNumber}
                </span>
                <span className="text-xl lg:text-md ml-2 text-gray-500">
                  {ovenId}
                </span>
              </div>
              <div
                className={
                  'flex items-center gap-4 h-10 text-xl lg:text-lg' +
                  (isUpdatingName ? ' opacity-20' : '')
                }
              >
                <EditNameForm />
                <Tooltip
                  trigger={
                    <div
                      className={`w-8 ${isSavingFavorites || favoritesLoading ? 'opacity-20' : 'cursor-pointer'}`}
                    >
                      <StarIcon
                        onClick={() => toggleFavorite()}
                        percentFilled={myFavorite ? 100 : 0}
                      />
                    </div>
                  }
                >
                  {myFavorite ? 'Favorited' : 'Click to Favorite'}
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className="text-xl text-center lg:text-lg">
          <ConnectionState connection={oven.connectivity} text={true} />
          {oven.connectivity?.connected && (
            <div>
              <span className="m-2 text-nowrap">
                IP: <span className="font-bold">{oven.ipAddress}</span>
              </span>
              <span className="m-2 text-nowrap">
                RSSI: <span className="font-bold">{oven.rssi}</span>
              </span>
            </div>
          )}
        </div>
        <div className="flex content-end justify-end items-center gap-2 px-2">
          <Tooltip
            trigger={
              <a
                className="text-blue-300 w-8 lg:w-6 cursor-pointer hover:text-blue-700"
                href={oven.aws_thing_url}
                rel="noreferrer"
                target="_blank"
              >
                <HardwareChipOutlineIcon />
              </a>
            }
          >
            View in AWS IoT
          </Tooltip>
          <Tooltip
            trigger={
              <a
                className="text-blue-300 w-8 lg:w-6 cursor-pointer hover:text-blue-700"
                href={oven.aws_cloudwatch_url}
                rel="noreferrer"
                target="_blank"
              >
                <CloudOutlineIcon />
              </a>
            }
          >
            View in CloudWatch Logs
          </Tooltip>
          <Tooltip
            trigger={
              <a
                className="text-blue-300 w-8 lg:w-6 cursor-pointer hover:text-blue-700"
                href="#ota"
              >
                <OTAPublishIcon />
              </a>
            }
          >
            OTA Firmware Update
          </Tooltip>
          <Tooltip
            trigger={
              <a
                className="text-blue-300 w-8 lg:w-6 cursor-pointer hover:text-blue-700"
                href={'/cookCycles?ovenId=' + ovenId}
                rel="noreferrer"
                target="_blank"
              >
                <FlameIcon />
              </a>
            }
          >
            View Cook Cycles
          </Tooltip>
        </div>
      </div>

      <div className="">
        <div className="glg:flex gap-4 items-top gxl:items-center justify-around">
          <div className="lg:w-full my-2 max-w-[50rem]">
            <BasicDetails />
          </div>
          <div className="lg:w-full my-2 flex-grow">
            <OvenCommandsExecutor ovenId={ovenId} />
          </div>
        </div>
        <div className="gxl:flex gap-4 items-top justify-around">
          <div className="lg:w-full gxl:w-[60%]">
            <OvenEventViewer ovenId={ovenId} />
          </div>
          <div className="lg:w-full gxl:w-[40%]">
            <ThingJobHistory ovenId={ovenId} />
          </div>
        </div>
        <div className="flex-col space-y-4 min-w-96 my-4 w-full">
          <OvenLogs ovenId={ovenId} />
        </div>
      </div>

      {editOpen && (
        <EditThingsModal
          invalidate={() => {
            queryClient.refetchQueries(['oven-details', ovenId])
          }}
          selected={[oven]}
          setEditOpen={(isOpen) => (isOpen ? setPopup('#edit') : setPopup())}
        />
      )}

      {otaOpen && (
        <Modal onCloseModal={() => setPopup()}>
          <ModalHeader onClickClose={() => setPopup()}>
            <span className="text-xl font-bold">Publish OTA</span>
          </ModalHeader>
          <ImagePublish defaultDevices={[ovenId]} />
        </Modal>
      )}
    </div>
  )
}

export default PreOvenPage
