import { ComponentProps } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Link({ href, children, ...rest }: ComponentProps<'a'>) {
  const navigate = useNavigate()

  return (
    <a
      className="text-blue-400 cursor-pointer hover:underline"
      href={href}
      onClick={(e) => {
        if (!href) {
          return
        }

        e.preventDefault()
        navigate(href)
      }}
      {...rest}
    >
      {children}
    </a>
  )
}
