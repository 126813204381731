import { clsx } from 'clsx'
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react'

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  hasError?: boolean
  rightIcon?: ReactNode
}

export const INPUT_CLASSES =
  'h-10 w-full rounded border bg-white-900 px-2 disabled:bg-grey-904 disabled:cursor-not-allowed'

const Input = forwardRef<HTMLInputElement, Props>(
  ({ hasError = false, rightIcon = null, ...rest }, ref) => {
    const input = (
      <input
        ref={ref}
        className={clsx(INPUT_CLASSES, {
          'border-red-902': hasError,
          'border-grey-901': !hasError,
          'pr-12': !!rightIcon,
        })}
        {...rest}
      />
    )

    return (
      <div className="relative">
        {input}
        {rightIcon && (
          <div className="absolute top-0 right-0 h-full w-12">
            <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
              {rightIcon}
            </div>
          </div>
        )}
      </div>
    )
  },
)

Input.displayName = 'Input'

export default Input
