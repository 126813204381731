import { useQuery } from '@tanstack/react-query'
import { Modal, ModalHeader, MultiCombobox } from '@tovala/component-library'
import { useState } from 'react'
import {
  addGroupToThings,
  fetchThingGroups,
  OvenData,
  removeGroupFromThings,
  getAuthToken,
} from 'utils/oatsApi'
import { clsx } from 'clsx'

const EditThingsModal = ({
  selected,
  setEditOpen,
  invalidate,
}: {
  selected: OvenData[]
  setEditOpen: (open: boolean) => void
  invalidate: () => void
}) => {
  const {
    data: groupsData,
    // isLoading: groupsIsLoading,
    // error: groupsError,
  } = useQuery({
    queryKey: ['thingGroups'],
    queryFn: async () => {
      const bearerToken = getAuthToken()
      return fetchThingGroups(bearerToken)
    },
  })

  const collectiveGroups = selected.reduce((acc, curr) => {
    const thingGroups = curr.thingGroupNames ?? []
    return thingGroups.reduce((acc2, curr2) => {
      if (!acc2.includes(curr2)) {
        return [...acc2, curr2]
      }
      return acc2
    }, acc)
  }, [] as string[])

  const [toRemove, setToRemove] = useState<string[]>([])
  const [toAdd, setToAdd] = useState<string[]>([])

  const [loading, setLoading] = useState(false)

  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  return (
    <Modal
      key={selected.map((oven) => oven.thingId).join('-')}
      onCloseModal={() => {
        setEditOpen(false)
      }}
    >
      <ModalHeader
        onClickClose={() => {
          setEditOpen(false)
        }}
      >
        <span className="text-xl font-bold">Edit Thing Groups</span>
      </ModalHeader>

      <div className="p-4 min-w-96 max-w-xl flex flex-col gap-4">
        <div>Groups to remove</div>
        <MultiCombobox
          isClearable={true}
          onChange={(value) => {
            if (!value) {
              setToRemove([])
              return
            }
            setToRemove(value.map((group) => group.value as string))
          }}
          options={collectiveGroups.map((group) => ({
            label: group,
            value: group,
          }))}
          value={toRemove.map((group) => ({
            label: group,
            value: group,
          }))}
        />

        <div>Groups to add</div>
        <MultiCombobox
          onChange={(value) => {
            if (!value) {
              setToAdd([])
              return
            }
            setToAdd(value.map((group) => group.value as string))
          }}
          options={
            groupsData?.map((group) => ({
              label: group.group_name,
              value: group.group_name,
            })) ?? []
          }
          value={toAdd.map((group) => ({
            label: group,
            value: group,
          }))}
        />
        {errorMsg && <div className="text-red p-2">{errorMsg}</div>}

        <div className="italic text-gray-500 text-sm">
          Updates made may take a few minutes to reflect
        </div>

        <div className="flex justify-end gap-4">
          <button
            className="bg-gray-400 text-white px-4 py-2 rounded-lg font-bold"
            onClick={() => {
              setEditOpen(false)
            }}
          >
            Cancel
          </button>{' '}
          <button
            className={clsx('text-white px-4 py-2 rounded-lg font-bold', {
              'bg-orange-1': !loading,
              'bg-gray-400 cursor-not-allowed': loading,
            })}
            onClick={async () => {
              if (loading) {
                return
              }
              console.log('Remove', toRemove)
              console.log('Add', toAdd)

              setLoading(true)

              const bearerToken = getAuthToken()

              try {
                for (const toAd of toAdd) {
                  console.log('Adding', toAd)
                  await addGroupToThings(
                    bearerToken,
                    toAd,
                    selected.map((oven) => oven.thingName),
                  )
                }

                for (const toRem of toRemove) {
                  console.log('Removing', toRem)
                  await removeGroupFromThings(
                    bearerToken,
                    toRem,
                    selected.map((oven) => oven.thingName),
                  )
                }
              } catch (error) {
                setErrorMsg('Failed to update groups')
                console.error(error)
                setLoading(false)
                return
              }

              setLoading(false)
              setEditOpen(false)
              invalidate()
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default EditThingsModal
