import { useState } from 'react'
import {
  Brush,
  CartesianGrid,
  ComposedChart,
  Label,
  LabelProps,
  Legend,
  Line,
  LineProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CurveType } from 'recharts/types/shape/Curve'

export type KeysOfType<T, U> = {
  [K in keyof T]: T[K] extends U ? K : never
}[keyof T]

export type LineProp<T> = LineProps & {
  name: string
  dataKey: KeysOfType<T, number>
}

export type Props<T> = {
  data: T[]
  lines: LineProp<T>[]
  xAxisDataKey?: KeysOfType<T, number>
  yAxisDataKey?: KeysOfType<T, number>
  defaultType?: CurveType
  defaultIsAnimationActive?: boolean
  grid?: boolean
  allowHiding?: boolean
  defaultHidden?: KeysOfType<T, number>[]
  xAxisLabel?: string | LabelProps
  yAxisLabel?: string | LabelProps
  children?: React.ReactNode
  brush?: boolean
  tooltip?: boolean
  legend?: boolean
  syncId?: string
}

export function Chart<T>({
  data,
  lines,
  xAxisDataKey,
  yAxisDataKey,
  defaultIsAnimationActive = true,
  defaultType = 'monotone',
  grid = true,
  allowHiding = true,
  defaultHidden = [],
  xAxisLabel,
  yAxisLabel,
  children,
  brush = false,
  tooltip = true,
  legend = true,
  syncId,
}: Props<T>) {
  const [hidden, setHidden] = useState<string[]>(defaultHidden as string[])

  return (
    <div className="w-full h-full">
      <ResponsiveContainer height={'100%'} width={'100%'}>
        <ComposedChart data={data} syncId={syncId}>
          {children}

          {lines.map(({ id, name, dataKey, ...rest }, i) => (
            <Line
              dot={data.length > 50 ? false : undefined}
              id={id}
              isAnimationActive={defaultIsAnimationActive}
              type={defaultType}
              {...rest}
              key={i}
              ref={null}
              dataKey={dataKey as string}
              hide={allowHiding && hidden.includes(dataKey as string)}
              label={name}
              name={name}
            />
          ))}
          {grid && <CartesianGrid stroke="#ccc" />}
          <XAxis dataKey={xAxisDataKey as string | undefined}>
            {xAxisLabel && (
              <Label
                offset={-3}
                {...(typeof xAxisLabel === 'string'
                  ? { value: xAxisLabel }
                  : xAxisLabel)}
                position="insideBottom"
              />
            )}
          </XAxis>
          <YAxis dataKey={yAxisDataKey as string | undefined}>
            {yAxisLabel && (
              <Label
                offset={-3}
                {...(typeof yAxisLabel === 'string'
                  ? { value: yAxisLabel }
                  : yAxisLabel)}
                position="insideBottom"
              />
            )}
          </YAxis>
          {tooltip && <Tooltip />}
          {legend && (
            <Legend
              height={36}
              onClick={(e) =>
                setHidden((prev) => {
                  if (!e.dataKey) {
                    return prev
                  }

                  if (prev.includes(e.dataKey as string)) {
                    return prev.filter((key) => key !== e.dataKey)
                  }

                  return [...prev, e.dataKey as string]
                })
              }
            />
          )}
          {brush && (
            <Brush
              dataKey={xAxisDataKey as string}
              height={30}
              stroke="#8884d8"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
