import { EndianTypes } from './structDataTypes'

export function twosComplement(n: number, bits: number): number {
  const mask = 1 << (bits - 1)
  return (n ^ mask) - mask
}

export function hexifyBuf(buf: Uint8Array, spaced: boolean = true) {
  const hex = buf
    .reduce(
      (acc, curr) =>
        acc + curr.toString(16).padStart(2, '0') + (spaced ? ' ' : ''),
      '',
    )
    .trim()
  return hex
}

/**
 * @param value - Number to split
 * @param size - Number of bytes to split
 * @param endian - Endian type
 * @returns Array of numbers in little endian order
 */
export function splitNumbers(value: number, size: number, endian: EndianTypes) {
  let numbers: number[] = []
  for (let i = 0; i < size; i++) {
    numbers.push((value >> (i * 8)) & 0xff)
  }
  if (endian === EndianTypes.BIG_ENDIAN) {
    numbers = numbers.reverse()
  }

  return numbers
}

/**
 * @param uint8Arr - Array of numbers to read in little endian order
 * @param offset - Offset to start reading from
 * @param size - Number of bytes to read
 * @param endian - Endian type
 * @returns Array of numbers in little endian order
 */
export function readEndian(
  uint8Arr: Uint8Array,
  offset: number,
  size: number,
  endian: EndianTypes,
) {
  const numbers: number[] = []
  for (let i = 0; i < size; i++) {
    numbers.push(uint8Arr[offset + i])
  }
  if (endian === EndianTypes.BIG_ENDIAN) {
    numbers.reverse()
  }

  return numbers
}

/**
 * @param numbers - Array of numbers to merge in little endian order
 */
export function mergeNumbers(numbers: number[]) {
  return numbers.reduce((acc, curr, i) => acc | (curr << (i * 8)), 0)
}
