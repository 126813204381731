import { Button, Input } from '@tovala/component-library'
import { useEffect, useState } from 'react'
import { OvenEvent, useRoutineHash, useRoutines } from 'utils/oatsApi'
import RenderedCookCycle from './RenderedCookCycle'
import { clsx } from 'clsx'
import { useSearchParams } from 'react-router-dom'

const SEARCH_OVEN_ID_SIZE = 100
const SEARCH_HASH_SIZE = 100

function CookCyclesPage() {
  const [searchParams] = useSearchParams()
  const ovenIdParam = searchParams.get('ovenId')
  const hashParam = searchParams.get('hash')

  const [ovenId, setOvenId] = useState<string>()
  const [hash, setHash] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const [searchedId, setSearchedId] = useState<string>()
  const [searchedHash, setSearchedHash] = useState<string>()

  const [selectedCookCycle, setSelectedCookCycle] = useState<OvenEvent>()
  const [referencedCookCycle, setReferencedCookCycle] = useState<OvenEvent>()

  function search(
    searchedId: string | undefined = ovenId,
    searchedHash: string | undefined = hash,
  ) {
    setErrorMessage('')
    setSearchedId('')
    setSearchedHash('')
    setSelectedCookCycle(undefined)
    setReferencedCookCycle(undefined)

    if (!searchedId && !searchedHash) {
      setErrorMessage('Please enter an Oven ID or Hash')
      return
    }

    if (searchedId && searchedHash) {
      setErrorMessage('Please enter only an Oven ID or Hash')
      return
    }

    if (searchedId) {
      setSearchedId(searchedId)
      return
    }

    setSearchedHash(searchedHash)
  }

  useEffect(() => {
    if (ovenIdParam) {
      setOvenId(ovenIdParam)
    }

    if (hashParam) {
      setHash(hashParam)
    }

    if (ovenIdParam || hashParam) {
      search(ovenIdParam as string, hashParam as string)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ovenIdParam, hashParam])

  return (
    <div>
      <div className="flex flex-row flex-wrap gap-4">
        <div className="w-96 shadow-lg p-4 rounded-lg">
          <h1 className="text-2xl font-bold">Cook Cycles</h1>
          <div>
            <div>
              <label>Oven ID</label>
            </div>
            <Input
              aria-label="Oven ID"
              onChange={(e) => setOvenId(e.target.value.trim())}
              value={ovenId}
            />
          </div>
          <div>
            <div>
              <label>Hash</label>
            </div>
            <Input
              aria-label="Hash"
              onChange={(e) => setHash(e.target.value.trim())}
              value={hash}
            />
          </div>

          {errorMessage && (
            <div>
              <p className="text-red-905">{errorMessage}</p>
            </div>
          )}

          <div className="mt-4">
            <Button onClick={() => search()} size="small">
              Search
            </Button>
          </div>
        </div>

        {(searchedId || searchedHash) && (
          <div className="w-fit min-w-96 p-4 shadow-lg rounded-lg">
            <div>
              <h2 className="text-2xl font-bold">Search Results</h2>
            </div>
            <div className="flex flex-col gap-2 mt-4 max-h-72 overflow-y-auto">
              {searchedId && (
                <SearchedIdList
                  searchedId={searchedId}
                  selected={selectedCookCycle?.cookCycleID}
                  setSelectedCookCycle={(e) => {
                    setSelectedCookCycle(e)
                    setReferencedCookCycle(undefined)
                  }}
                />
              )}
              {searchedHash && (
                <SearchedHashList
                  searchedHash={searchedHash}
                  selected={selectedCookCycle?.cookCycleID}
                  setSelectedCookCycle={(e) => {
                    setSelectedCookCycle(e)
                    setReferencedCookCycle(undefined)
                  }}
                />
              )}
            </div>
          </div>
        )}

        {selectedCookCycle && (
          <div className="w-fit min-w-96 p-4 shadow-lg rounded-lg">
            <div>
              <h2 className="text-2xl font-bold">Compared Cook Cycle</h2>
            </div>
            <div className="flex flex-col gap-2 mt-4 max-h-72 overflow-y-auto">
              <div
                className={clsx('cursor-pointer', {
                  'text-orange-1': !referencedCookCycle?.cookCycleID,
                })}
                onClick={() => setReferencedCookCycle(undefined)}
              >
                None
              </div>
              {searchedId && (
                <SearchedIdList
                  hidden={selectedCookCycle.cookCycleID}
                  searchedId={searchedId}
                  selected={referencedCookCycle?.cookCycleID}
                  setSelectedCookCycle={setReferencedCookCycle}
                />
              )}
              {searchedHash && (
                <SearchedHashList
                  hidden={selectedCookCycle.cookCycleID}
                  searchedHash={searchedHash}
                  selected={referencedCookCycle?.cookCycleID}
                  setSelectedCookCycle={setReferencedCookCycle}
                />
              )}
            </div>
          </div>
        )}
      </div>

      {selectedCookCycle && (
        <div className="mt-4">
          <RenderedCookCycle
            cookCycleId={selectedCookCycle.cookCycleID!}
            referencedCookCycleId={referencedCookCycle?.cookCycleID}
            startEvent={selectedCookCycle}
          />
        </div>
      )}
    </div>
  )
}

function SearchedIdList({
  searchedId,
  setSelectedCookCycle,
  selected,
  hidden,
}: {
  searchedId: string
  setSelectedCookCycle: (cookCycle: OvenEvent) => void
  selected: string | undefined
  hidden?: string
}) {
  const { data, isLoading, isError } = useRoutines(
    searchedId,
    0,
    SEARCH_OVEN_ID_SIZE,
  )

  if (!data || isLoading) {
    return (
      <div>
        <div>Loading</div>
      </div>
    )
  }

  if (isError) {
    return (
      <div>
        <div>Error</div>
      </div>
    )
  }

  if (data.length === 0) {
    return (
      <div>
        <div>No data</div>
      </div>
    )
  }

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Cook Cycle ID</th>
            <th>Device ID</th>
            <th>Event Time</th>
          </tr>
        </thead>
        <tbody>
          {data.map((startEvent) => {
            if (hidden && startEvent.cookCycleID === hidden) {
              return null
            }

            return (
              <tr
                key={startEvent.cookCycleID}
                className={clsx('cursor-pointer', {
                  'text-orange-1': selected === startEvent.cookCycleID,
                })}
                onClick={() => setSelectedCookCycle(startEvent)}
              >
                <td>{startEvent.cookCycleID}</td>
                <td className="px-2">{startEvent.deviceID}</td>
                <td>{new Date(startEvent.eventTimeMs).toLocaleString()}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

function SearchedHashList({
  searchedHash,
  setSelectedCookCycle,
  selected,
  hidden,
}: {
  searchedHash: string
  selected: string | undefined
  setSelectedCookCycle: (cookCycle: OvenEvent) => void
  hidden?: string
}) {
  const { data, isLoading, isError } = useRoutineHash(
    searchedHash,
    0,
    SEARCH_HASH_SIZE,
  )

  if (!data || isLoading) {
    return (
      <div>
        <div>Loading</div>
      </div>
    )
  }

  if (isError) {
    return (
      <div>
        <div>Error</div>
      </div>
    )
  }

  const startingEvents = data.filter(
    (event) => event.eventKey === 'cookingStarted',
  )

  if (startingEvents.length === 0) {
    return (
      <div>
        <div>No data</div>
      </div>
    )
  }

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Cook Cycle ID</th>
            <th>Device ID</th>
            <th>Event Time</th>
          </tr>
        </thead>
        <tbody>
          {startingEvents.map((startEvent) => {
            if (hidden && startEvent.cookCycleID === hidden) {
              return null
            }

            return (
              <tr
                key={startEvent.cookCycleID}
                className={clsx('cursor-pointer', {
                  'text-orange-1': selected === startEvent.cookCycleID,
                })}
                onClick={() => setSelectedCookCycle(startEvent)}
              >
                <td>{startEvent.cookCycleID}</td>
                <td className="px-4">{startEvent.deviceID}</td>
                <td>{new Date(startEvent.eventTimeMs).toLocaleString()}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default CookCyclesPage
