import {
  mergeNumbers,
  readEndian,
  splitNumbers,
  twosComplement,
} from './bitUtils'
import { EndianTypes, StructDataType } from './structDataTypes'

// Primitive data types
export const uint8: StructDataType<number> = {
  name: 'uint8',
  size: 1,
  alignment: 1,
  serialize: (value, uint8Arr, offset) => {
    uint8Arr[offset] = value
    return uint8.size
  },
  deserialize: (uint8Arr, offset) => uint8Arr[offset],
}

export const int8: StructDataType<number> = {
  name: 'int8',
  size: 1,
  alignment: 1,
  serialize: (value, uint8Arr, offset) => {
    uint8Arr[offset] = twosComplement(value, 8)
    return int8.size
  },
  deserialize: (uint8Arr, offset) => twosComplement(uint8Arr[offset], 8),
}

export const uint16: StructDataType<number> = {
  name: 'uint16',
  size: 2,
  alignment: 2,
  serialize: (value, uint8Arr, offset, endian: EndianTypes) => {
    const numbers = splitNumbers(value, uint16.size as number, endian)
    numbers.forEach((num, i) => {
      uint8Arr[offset + i] = num
    })
    return uint16.size
  },
  deserialize: (uint8Arr, offset, endian: EndianTypes) => {
    const numbers = readEndian(uint8Arr, offset, uint16.size as number, endian)
    const mergedNumbers = mergeNumbers(numbers)
    return mergedNumbers
  },
}

export const int16: StructDataType<number> = {
  name: 'int16',
  size: 2,
  alignment: 2,
  serialize: (value, uint8Arr, offset, endian: EndianTypes) => {
    const numbers = splitNumbers(value, int16.size as number, endian)
    numbers.forEach((num, i) => {
      uint8Arr[offset + i] = num
    })
    return int16.size
  },
  deserialize: (uint8Arr, offset, endian: EndianTypes) => {
    const numbers = readEndian(uint8Arr, offset, int16.size as number, endian)
    const mergedNumbers = mergeNumbers(numbers)
    return twosComplement(mergedNumbers, 16)
  },
}

export const uint32: StructDataType<number> = {
  name: 'uint32',
  size: 4,
  alignment: 4,
  serialize: (value, uint8Arr, offset, endian: EndianTypes) => {
    const numbers = splitNumbers(value, uint32.size as number, endian)
    numbers.forEach((num, i) => {
      uint8Arr[offset + i] = num
    })
    return uint32.size
  },
  deserialize: (uint8Arr, offset, endian: EndianTypes) => {
    const numbers = readEndian(uint8Arr, offset, uint32.size as number, endian)
    const mergedNumbers = mergeNumbers(numbers)
    return twosComplement(mergedNumbers, 32)
  },
}

export const int32: StructDataType<number> = {
  name: 'int32',
  size: 4,
  alignment: 4,
  serialize: (value, uint8Arr, offset, endian: EndianTypes) => {
    const numbers = splitNumbers(value, int32.size as number, endian)
    numbers.forEach((num, i) => {
      uint8Arr[offset + i] = num
    })
    return int32.size
  },
  deserialize: (uint8Arr, offset, endian: EndianTypes) => {
    const numbers = readEndian(uint8Arr, offset, int32.size as number, endian)
    const mergedNumbers = mergeNumbers(numbers)
    return mergedNumbers
  },
}

export const char: StructDataType<string> = {
  name: 'char',
  size: 1,
  alignment: 1,
  serialize: (value: string, uint8Arr: Uint8Array, offset: number) => {
    uint8Arr[offset] = value.charCodeAt(0)
    return char.size
  },
  deserialize: (uint8Arr: Uint8Array, offset: number) =>
    String.fromCharCode(uint8Arr[offset]),
}

export const charArray = (len: number) => {
  const charArray: StructDataType<string> = {
    name: 'charArray',
    size: len,
    alignment: 1,
    serialize: (value: string, uint8Arr: Uint8Array, offset: number) => {
      for (let i = 0; i < Math.min(value.length, len); i++) {
        uint8Arr[offset + i] = value.charCodeAt(i)
      }

      // Fill the rest of the array with 0s
      for (let i = value.length; i < len; i++) {
        uint8Arr[offset + i] = 0
      }

      uint8Arr[offset + len] = 0
      return len
    },
    deserialize: (uint8Arr: Uint8Array, offset: number) => {
      let value = ''
      while (uint8Arr[offset] !== 0) {
        value += String.fromCharCode(uint8Arr[offset])
        offset++
      }
      return value
    },
  }
  return charArray
}

export const dataTypeArray = <T>(dataType: StructDataType<T>, len: number) => {
  const array: StructDataType<T[]> = {
    name: 'dataTypeArray_' + dataType.name,
    size: len * dataType.size,
    alignment: dataType.alignment,
    serialize: (
      value: T[],
      uint8Arr: Uint8Array,
      offset: number,
      endian: EndianTypes,
    ) => {
      let newOffset = offset
      value.forEach((val) => {
        newOffset += dataType.serialize(val, uint8Arr, newOffset, endian)
      })
      return newOffset - offset
    },
    deserialize: (
      uint8Arr: Uint8Array,
      offset: number,
      endian: EndianTypes,
    ) => {
      const values: T[] = []
      for (let i = 0; i < len; i++) {
        values.push(
          dataType.deserialize(uint8Arr, offset + i * dataType.size, endian),
        )
      }
      return values
    },
  }
  return array
}
