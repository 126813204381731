export const LogoLockUpIcon = ({ isProduction }: { isProduction: boolean }) => {
  const className =
    'flex items-center space-x-2' + (isProduction ? ' fill-orange-1' : '')

  return (
    <div className={className}>
      <div className="w-32 h-4">
        <svg
          fill="none"
          viewBox="0 0 122 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 5.33394H5.32046V0H0V5.33394ZM10.6428 0V5.33394H15.965V0H10.6428ZM0 16H5.32046V10.6661H0V16ZM10.6428 16H15.965V10.6661H10.6428V16Z"
            fill={'#FF6A1B'}
          />
          <path
            d="M35.7767 15.7617V2.05329H41.0203V0.238176H28.4629V2.05329H33.7101V15.7617H35.7767Z"
            fill="currentColor"
          />
          <path
            clipRule="evenodd"
            d="M58.6115 8C58.6115 3.264 55.6562 0 51.6011 0C47.546 0 44.5676 3.264 44.5676 8C44.5676 12.736 47.546 16 51.6011 16C55.6562 16 58.6115 12.736 58.6115 8ZM46.6342 8C46.6342 4.32711 48.6884 1.83822 51.6011 1.83822C54.5138 1.83822 56.568 4.32711 56.568 8C56.568 11.6729 54.5174 14.1618 51.6011 14.1618C48.6848 14.1618 46.6342 11.6729 46.6342 8Z"
            fill="currentColor"
            fillRule="evenodd"
          />

          <path
            d="M66.7417 15.7617L60.8506 0.238176H63.0732L67.8628 13.168L72.6523 0.238176H74.7668L68.9129 15.7617H66.7417Z"
            fill="currentColor"
          />
          <path
            d="M94.2669 15.7617H104.062V13.9466H96.3389V0.238176H94.2669V15.7617Z"
            fill="currentColor"
          />
          <path
            clipRule="evenodd"
            d="M115.602 0.238176H113.313L107.337 15.7617H109.409L110.615 12.5866L110.686 12.4088H118.19L118.399 12.9546L119.485 15.7688H121.576L115.602 0.238176ZM111.376 10.5848L114.422 2.5724L116.778 8.69151V8.70218L117.223 9.85951L117.5 10.5848H111.376Z"
            fill="currentColor"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M81.8623 0.238176H84.1488L90.1198 15.7688H88.0336L86.948 12.9546L86.7387 12.4088H79.2351L79.1642 12.5866L77.9579 15.7617H75.886L81.8623 0.238176ZM79.9252 10.5848H86.0398L85.7631 9.85951L85.3178 8.70218V8.69151L82.9621 2.5724L79.9252 10.5848Z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      </div>
      <span className="text-l">
        OATS
        {!isProduction && <span className="text-orange-1 px-1"> DEV</span>}
      </span>
    </div>
  )
}

export const ExpandIcon = () => (
  <svg fill="none" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="m 0.50287107,9.0255923 c -0.29289,-0.2929 -0.29289,-0.7677 0,-1.0606 l 3.21963003,-3.2197 -3.21963003,-3.21967 c -0.29289,-0.2928899 -0.29289,-0.76776995 0,-1.06065995 0.29293,-0.29289 0.76773003,-0.29289 1.06063003,0 l 3.75,3.75002995 c 0.2929,0.2929 0.2929,0.7677 0,1.0606 l -3.75,3.75 c -0.2929,0.2929 -0.76770003,0.2929 -1.06063003,0 z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export const HardwareChipOutlineIcon = () => {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <rect
        fill="none"
        height="352"
        rx="48"
        ry="48"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="32"
        width="352"
        x="80"
        y="80"
      />
      <rect
        fill="none"
        height="224"
        rx="16"
        ry="16"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="32"
        width="224"
        x="144"
        y="144"
      />
      <path
        d="M256 80V48M336 80V48M176 80V48M256 464v-32M336 464v-32M176 464v-32M432 256h32M432 336h32M432 176h32M48 256h32M48 336h32M48 176h32"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
    </svg>
  )
}

export const CloudOutlineIcon = () => {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M400 240c-8.89-89.54-71-144-144-144-69 0-113.44 48.2-128 96-60 6-112 43.59-112 112 0 66 54 112 120 112h260c55 0 100-27.44 100-88 0-59.82-53-85.76-96-88z"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="32"
      />
    </svg>
  )
}

export const CircleFilledIcon = () => {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <circle cx="256" cy="256" fill="currentColor" r="86" />
    </svg>
  )
}
export const RewindIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 19l-7-7 7-7v14zm10 0l-7-7 7-7v14z" fill="currentColor" />
    </svg>
  )
}
export const LoadingIcon = () => {
  return (
    <svg
      className="animate-spin h-5 w-5 text-gray-500"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        fill="currentColor"
      ></path>
    </svg>
  )
}
export const ExclamationCircleIcon = () => {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="256"
        cy="256"
        fill="none"
        r="230"
        stroke="currentColor"
        strokeWidth="50"
      />
      <line
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="50"
        x1="256"
        x2="256"
        y1="128"
        y2="288"
      />
      <circle cx="256" cy="384" fill="currentColor" r="16" />
    </svg>
  )
}

export const OTAPublishIcon = () => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="none"
        height="48"
        rx="8"
        ry="8"
        stroke="currentColor"
        strokeWidth="4"
        width="48"
        x="8"
        y="8"
      />
      <path
        d="M32 16v16M32 32l-6-6M32 32l6-6"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
      <path
        d="M16 48h32"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </svg>
  )
}

export const FlameIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.59 63.91C23.21 64.24 23.87 63.52 23.49 62.93C21.41 59.69 19.44 54.25 22.58 47.25C27.80 35.56 30.98 29.51 30.98 29.51C30.98 29.51 32.68 36.57 37.26 42.85C41.68 48.90 44.09 56.50 40.20 62.83C39.83 63.42 40.48 64.12 41.10 63.81C45.92 61.34 51.32 56.39 51.93 46.55C52.16 43.54 51.82 39.33 50.14 34.02C47.97 27.28 45.30 24.14 43.76 22.79C43.30 22.38 42.58 22.74 42.62 23.35C43.07 30.62 40.33 32.47 38.78 28.31C38.15 26.65 37.79 23.78 37.79 20.28C37.79 14.46 36.10 8.47 32.38 3.60C31.41 2.33 30.28 1.15 28.98 0.14C28.51 -0.21 27.83 0.14 27.87 0.74C28.15 4.68 27.90 15.99 17.97 29.50C8.98 42.04 12.46 51.66 13.70 54.27C16.06 59.26 19.36 62.20 22.59 63.91Z"
        fill="currentColor"
      />
    </svg>
  )
}
