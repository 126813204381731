import { useCallback, useEffect, useRef, useState } from 'react'

import { minutesToMs } from '@tovala/browser-apis-core'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import Input from 'components/common/Input'
import SearchIcon from 'components/common/SearchIcon'
import Dropdown from 'components/common/Dropdown'
import { DropdownMenu, MenuItem } from 'components/common/DropdownMenu'
import { useThingGroups, ThingGroupData } from 'utils/oatsApi'

export type OvenSearchRequest = {
  enabled: boolean
  searchTerm: string
}

export interface OvenSearchResult {
  thingName: string
  thingGroupNames: string[]
  favoriteName: string
}

export type OvenSearchResponse = {
  ovens: OvenSearchResult[]
  total: number
}

export function useOvenSearch({
  enabled = false,
  searchTerm,
  ...rest
}: OvenSearchRequest &
  Omit<
    UseQueryOptions<OvenSearchResponse, Error>,
    'queryFn' | 'queryKey' | 'staleTime'
  >) {
  return useQuery<OvenSearchResponse, Error>({
    ...rest,
    enabled,
    queryKey: [
      'device-search',
      { data: { key: searchTerm }, page: 0, limit: 10 },
    ],
    staleTime: minutesToMs(1),
  })
}

type SearchParams = {
  search: string
  searchKey: number
  onSearch: (results: string) => void
  searchGroup: string
}

const OvenSearch = ({
  search,
  onSearch,
  searchKey,
  searchGroup,
}: SearchParams) => {
  const handleKeyPress = useCallback((ev: KeyboardEvent) => {
    if (ev.key === '/') {
      const input = document.getElementById('searchTerm') as HTMLInputElement
      if (input !== document.activeElement) {
        input?.focus()
        window.scrollTo(0, 0)
        ev.preventDefault()
      }
    }
  }, [])

  const { data: allThingGroups } = useThingGroups()
  const allConfigGroups = [] as ThingGroupData[]

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress)

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleKeyPress])

  const [searchGroupFilter, setSearchGroupFilter] = useState<string>('')
  const searchGroups = (allThingGroups || [])
    .concat(allConfigGroups)
    .map((e) => e.group_name)
    .filter((e) => !searchGroupFilter.trim() || e.includes(searchGroupFilter))

  const [searchGroupOpen, setSearchGroupOpen] = useState<boolean>(false)
  const selectSearchGroup = (group: string) => {
    setSearchGroupOpen(false)
    submitSearch({ group })
  }
  const searchGroupOpenChange = (open: boolean) => {
    return setSearchGroupOpen(open)
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const submitSearch = ({ group }: { group?: string }) => {
    const key = inputRef.current?.value || ''

    // If we're searching for a thing or config group by key, use those
    if (searchGroups.includes(key)) {
      onSearch('+group:' + key)
    } else {
      const groupStr = group ? '+group:' + group : ''

      onSearch(
        [key, groupStr]
          .filter((e) => e !== '')
          .filter((e) => e.trim())
          .join(' '),
      )
    }
  }

  // eslint-disable-next-line
  return (
    <div className="flex justify-between items-center gap-1">
      <form
        className="w-[60%]"
        onSubmit={(e) => {
          e.preventDefault()
          submitSearch({ group: searchGroup })
        }}
      >
        <Input
          key={searchKey}
          ref={inputRef}
          defaultValue={search}
          id="searchTerm"
          placeholder='Search devices by "name", id, serial number, group, firmware, model, userid...'
          rightIcon={
            <div className="h-4 w-4 text-gray-400">
              <SearchIcon />
            </div>
          }
        />
      </form>

      <div className="w-[40%]">
        <Dropdown
          open={searchGroupOpen}
          setOpen={searchGroupOpenChange}
          trigger={
            <Input
              key={searchKey}
              defaultValue={searchGroup}
              id="searchGroup"
              onChange={(e) => setSearchGroupFilter(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  submitSearch({ group: searchGroupFilter })
                }
              }}
              placeholder="Search Group..."
            />
          }
        >
          <DropdownMenu>
            {[
              <MenuItem
                key="<none>"
                label="<none>"
                onClick={() => selectSearchGroup('')}
                styles="text-gray-200"
              />,
            ].concat(
              searchGroups.map((group) => (
                <MenuItem
                  key={group}
                  label={group}
                  onClick={() => selectSearchGroup(group)}
                />
              )),
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  )
}

export default OvenSearch
