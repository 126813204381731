import { Chart } from 'components/common/Chart'
import { ReferenceArea } from 'recharts'
import { cookModeColors, Routine } from 'utils/meals'
import { convertToPrettyString } from 'utils/stringUtils'

type PlotableRoutine = Routine & { startTime: number; endTime: number }

export function RoutineViewer({ routine }: { routine: Routine[] }) {
  const plotableRoutine = routine.map((r) => ({
    ...r,
    startTime: 0,
    endTime: 0,
  })) as PlotableRoutine[]

  for (let i = 0; i < plotableRoutine.length; i++) {
    const prevRoutine = plotableRoutine[i - 1]
    const currentRoutine = plotableRoutine[i]

    if (prevRoutine) {
      currentRoutine.startTime = prevRoutine.endTime
    }

    currentRoutine.endTime = currentRoutine.startTime + currentRoutine.time
  }

  console.log(plotableRoutine)

  const maxTime = Math.max(...plotableRoutine.map((r) => r.endTime))

  const fillerData: { i: number; t: 0 }[] = new Array(maxTime + 1)
    .fill({
      i: 0,
      t: 0,
    })
    .map((_, i) => ({ i, t: 0 }))

  return (
    <div className="w-full h-full">
      <Chart
        data={fillerData}
        legend={false}
        lines={[
          {
            dataKey: 't',
            name: '',
          },
        ]}
        tooltip={false}
        xAxisDataKey="i"
      >
        {plotableRoutine.map((r, i) => (
          <ReferenceArea
            key={i}
            fill={cookModeColors[r.mode]}
            fillOpacity={i % 2 === 0 ? 0.1 : 0.25}
            ifOverflow="extendDomain"
            label={{
              value: convertToPrettyString(r.mode),
              position: 'insideTop',
            }}
            x1={r.startTime}
            x2={r.endTime}
            y1={0}
            y2={r.temperature}
          />
        ))}
      </Chart>
    </div>
  )
}
