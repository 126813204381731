import { getEnvVar } from './env'
import { getAuthToken, GenericResponse } from './oatsApi'

export type OatsOvenCommandUnlock = undefined
export type OatsOvenCommandIdentify = undefined
export type OatsOvenCommandRestart = {
  force: string
}
export type OatsOvenCommandSetRelays = {
  lamp: boolean
  convection: boolean
  cooling: boolean
}
export type OatsOvenCommandLoadRoutine = {
  encoded_routine: string
}
export type OatsOvenCommandScanBarcode = {
  barcode: string
}
export type OatsOvenCommandSendKey = {
  key: string
}
export type OatsOvenCommandSendCommand = {
  command: string
}
export type OatsOvenCommandSetLogLevel = {
  tag: string
  level: number
}

export type OatsOvenCommands = {
  unlock: OatsOvenCommandUnlock
  identify: OatsOvenCommandIdentify
  restart: OatsOvenCommandRestart

  set_relays: OatsOvenCommandSetRelays
  load_routine: OatsOvenCommandLoadRoutine
  press_key: OatsOvenCommandSendKey

  scan_barcode: OatsOvenCommandScanBarcode
  send_command: OatsOvenCommandSendCommand

  log_level: OatsOvenCommandSetLogLevel
}

type DefaultParams = {
  prettyName: string
}

export type StringParams = {
  type: 'string'
  default?: string
  options?: string[] | Record<string, string | number>
} & DefaultParams

export type NumberParams = {
  type: 'number'
  default?: number
  min?: number
  max?: number
} & DefaultParams

export type BooleanParams = {
  type: 'boolean'
  default?: boolean
} & DefaultParams

export type ChoiceParams = {
  type: 'radio'
  options: Record<string, string>
  default: string
} & DefaultParams

export type OvenCommandParamsTypes =
  | StringParams
  | NumberParams
  | BooleanParams
  | ChoiceParams

export const OvenCommandParams: {
  [K in keyof OatsOvenCommands]: Record<
    keyof OatsOvenCommands[K],
    OvenCommandParamsTypes
  > &
    DefaultParams
} = {
  unlock: {
    prettyName: 'Unlock',
  },

  identify: {
    prettyName: 'Flash and Beep',
  },

  log_level: {
    prettyName: 'Set Log Level',
    tag: {
      prettyName: 'Log Tag',
      type: 'string',
    },
    level: {
      prettyName: 'Level',
      type: 'string',
      options: {
        5: 'Verbose',
        4: 'Debug',
        3: 'Info',
        2: 'Warning',
        1: 'Error',
      },
    },
  },

  restart: {
    prettyName: 'Restart',
    force: {
      type: 'radio',
      prettyName: '',
      options: { Polite: '', Force: 'force' },
      default: '',
    },
  },

  set_relays: {
    prettyName: 'Set Relays',
    lamp: { prettyName: 'Lamp', type: 'boolean' },
    convection: { prettyName: 'Convection Fan', type: 'boolean' },
    cooling: { prettyName: 'Cooling Fan', type: 'boolean' },
  },

  load_routine: {
    prettyName: 'Load Routine',
    encoded_routine: { prettyName: 'Base64-encoded Routine', type: 'string' },
  },

  scan_barcode: {
    prettyName: 'Scan Barcode',
    barcode: { prettyName: 'Barcode', type: 'string' },
  },

  press_key: {
    prettyName: 'Press Key',
    key: {
      prettyName: 'Key',
      type: 'string',
      options: {
        SCAN: 32,
        BAKE: 33,
        BROIL: 36,
        REHEAT: 35,
        MODEX: 34,
        TOAST: 31,
        TEMP_UP: 26,
        TEMP_DOWN: 25,
        TIME_UP: 18,
        TIME_DOWN: 17,
        START: 9,
        CANCEL: 10,
      },
    },
  },

  send_command: {
    prettyName: 'Send Command',
    command: { prettyName: 'Command', type: 'string' },
  },
}

export type OvenCommands = keyof OatsOvenCommands

export type OvenCommandEmpty = keyof OatsOvenCommands &
  {
    [K in keyof OatsOvenCommands]: OatsOvenCommands[K] extends undefined
      ? K
      : never
  }[keyof OatsOvenCommands]

export type OvenCommandWithProps = keyof OatsOvenCommands &
  {
    [K in keyof OatsOvenCommands]: OatsOvenCommands[K] extends undefined
      ? never
      : K
  }[keyof OatsOvenCommands]

export const ovenCommands = [
  'unlock',
  'identify',
  'restart',
  'set_relays',
  'load_routine',
  'scan_barcode',
  'press_key',
] as OvenCommands[]

export async function sendOvenCommand<T extends OvenCommands>(
  ...args: T extends OvenCommandEmpty
    ? [thingId: string, command: T]
    : T extends OvenCommandWithProps
      ? [thingId: string, command: T, props: OatsOvenCommands[T]]
      : never
) {
  const [thingId, command, props] = args
  const url = `${getEnvVar('OATS_API_URL')}/api/oven/${thingId}`
  const body = {
    command,
    ...props,
  }

  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body: JSON.stringify(body),
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<string>

  return json.data
}
