import { clsx } from 'clsx'
import { ReactNode, useState } from 'react'

type Props = {
  title: ReactNode
  children: ReactNode
  defaultOpen?: boolean
  noPadding?: boolean
  noOutline?: boolean
}

export default function Accordion({
  children,
  title,
  defaultOpen,
  noPadding,
  noOutline,
}: Props) {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  return (
    <div className="rounded-lg">
      <div
        className={clsx(
          'flex items-center justify-between p-4 cursor-pointer border-gray-300 border rounded-lg',
        )}
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        <h2 className="text-lg font-medium">{title}</h2>
        <svg
          className={clsx(`h-6 w-6`, {
            'rotate-0': isOpen,
          })}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={isOpen ? 'M19 9l-7 7-7-7' : 'M5 15l7-7 7 7'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
          />
        </svg>
      </div>
      {isOpen && (
        <div
          className={clsx({
            'p-4': !noPadding,
            'border-t border-gray-300': !noOutline,
          })}
        >
          {children}
        </div>
      )}
    </div>
  )
}
