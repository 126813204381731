import { lazy, Suspense, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { analytics } from 'utils/analytics'

import Header from 'components/common/Header'

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
)

const App = () => {
  const [showDevtools, setShowDevtools] = useState(false)

  const location = useLocation()

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  useEffect(() => {
    analytics.page()
  }, [location.pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className="space-y-10 bg-grey-0">
      <Header />

      <main className="px-4 min-w-[512px]">
        <Outlet />
      </main>

      {/* See "Additional Context" on https://tovala.atlassian.net/browse/WAT-431. The tl;dr is that
      Chrome seems to sometimes think the user is not online when they actually are (or at least the
      navigator.onLine property is not set properly.) */}
      <OfflineUsersTracking />

      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </div>
  )
}

export default App

const OfflineUsersTracking = () => {
  useEffect(() => {
    if (navigator?.onLine !== true) {
      Sentry.captureMessage('User is potentially offline on page load.')
    }
  }, [])

  return null
}
