/**
 * @param data Raw data to be exported
 * @param processRow Function to process each row of data
 * @param headers Object containing the headers of the CSV
 * @param order Order of the headers
 * @param filename Name of the file to be downloaded
 * @param arbitraryHeaders Optional headers to be added to the CSV
 */
export function exportAsCSV<T, O>(
  data: T[],
  processRow: (row: T) => O,
  headers: { [key in keyof O]: string },
  order: (keyof O)[],
  filename: string,
  arbitraryHeaders?: string[][],
) {
  const csvArr = [
    ...(arbitraryHeaders?.map((header) => header.join(',')) ?? []),
  ]
  const header = order.map((key) => headers[key])
  csvArr.push(header.join(','))

  for (const row of data) {
    const processedRow = processRow(row)
    const rowArr = order.map((key) => processedRow[key])
    csvArr.push(rowArr.join(','))
  }

  const csv = csvArr.join('\n')

  const blob = new Blob([csv], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename.endsWith('.csv') ? filename : `${filename}.csv`
  a.click()
  URL.revokeObjectURL(url)
}
