import { MealIdLookup, MealJsonPacker, MealRoutineCreator } from './MealLookup'
import TestCodes from './TestCodes'

const QRCodesPage = () => {
  return (
    <div>
      <div className="space-y-6 mb-16">
        <h1 className="text-k/44_110 font-medium md:text-k/36_110">Ovens</h1>
        <TestCodes />
      </div>
      <hr />
      <div className="space-y-6 my-16">
        <h1 className="text-k/44_110 font-medium md:text-k/36_110">Meals</h1>
        <div className="flex flex-row flex-wrap gap-6">
          <MealIdLookup />
          <MealJsonPacker />
        </div>
        <MealRoutineCreator />
      </div>
    </div>
  )
}

export default QRCodesPage
