import { OvenData } from 'utils/oatsApi'
import { Tooltip } from '@tovala/component-library'
import { CircleFilledIcon } from 'components/common/Icons'

interface Map {
  [key: string]: string | undefined
}

export const stateColors: Map = {
  online: 'text-green-906',
  offline: 'text-red',
  unknown: 'text-gray-300',
}

const ConnectionState = ({
  connection,
  text = false,
}: {
  connection: OvenData['connectivity']
  text?: boolean
}) => {
  const [color, state, timestamp, reason] =
    connection && connection.timestamp
      ? connection.connected
        ? [stateColors.online, 'Connected', connection.timestamp, '']
        : [
            stateColors.offline,
            'Disconnected',
            connection.timestamp,
            connection.disconnectReason,
          ]
      : [stateColors.unknown, 'Never Connected', undefined, '']

  return text ? (
    <div aria-label={state} className={color}>
      <div className="text-md">
        <span>{state}</span>
        <span className="text-nowrap">
          {timestamp ? ' @ ' + new Date(timestamp).toLocaleString() : ''}
        </span>
      </div>
      <div className="text-sm">{reason}</div>
    </div>
  ) : (
    <span aria-label={state} className={color}>
      <Tooltip
        trigger={
          <div>
            <CircleFilledIcon />
          </div>
        }
      >
        <div>
          {state +
            (timestamp ? ' @ ' + new Date(timestamp).toLocaleString() : '')}
        </div>
        <div className="text-xs">{reason}</div>
      </Tooltip>
    </span>
  )
}

export default ConnectionState
