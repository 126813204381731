import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  Side,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import React, { cloneElement, CSSProperties, useRef } from 'react'

type Props = {
  alwaysShow?: boolean
  children: React.ReactNode
  trigger: JSX.Element
  placement?: Placement
  arrow?: boolean
  setOpen?: (open: boolean) => void
  open?: boolean
}

const ARROW_SIDE_MAP: { [side in Side]: Side } = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
}

const Dropdown = ({
  children,
  trigger,
  open,
  setOpen,
  placement = 'bottom',
  arrow: useArrow = false,
}: Props) => {
  const arrowRef = useRef(null)

  const {
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement: finalPlacement,
    refs,
    strategy,
    x,
    y,
  } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(8),
      flip(),
      shift({
        padding: 8,
      }),
      arrow({ element: arrowRef }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useFocus(context),
    useRole(context, { role: 'menu' }),
    useDismiss(context),
  ])

  const sharedArrowClasses = 'absolute w-2 h-2'

  const sideFromPlacement = finalPlacement.split('-')[0] as Side

  const staticSide = ARROW_SIDE_MAP[sideFromPlacement]

  const arrowStyle: CSSProperties = { top: arrowY, left: arrowX }
  if (staticSide) {
    arrowStyle[staticSide] = '-4px'
  }

  const duplicateRef = (node: HTMLElement) => {
    const ref = cloneElement(trigger).ref as React.MutableRefObject<HTMLElement>
    if (ref) {ref.current = node}
    refs.setReference(node)
  }

  return (
    <>
      {cloneElement(
        trigger,
        getReferenceProps({ ref: duplicateRef, ...trigger.props }),
      )}
      <FloatingPortal>
        {open && (
          <div
            ref={refs.setFloating}
            className=""
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            {...getFloatingProps()}
          >
            {children}

            {useArrow && (
              <div
                ref={arrowRef}
                className={sharedArrowClasses}
                style={arrowStyle}
              >
                <div
                  className={`${sharedArrowClasses} left-0 top-0 rotate-45 bg-slate-300 shadow-lg`}
                />
              </div>
            )}
          </div>
        )}
      </FloatingPortal>
    </>
  )
}

export default Dropdown
