import { ReactNode, useId, useState } from 'react'
import {
  Area,
  Brush,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { decodeURLSafe } from 'utils/b64'
import { cookModeColors, CookModes, Models } from 'utils/meals'
import { OvenEvent, useRoutineHash } from 'utils/oatsApi'
import { convertToPrettyString } from 'utils/stringUtils'
import {
  DataWidth,
  EndianTypes,
  int16,
  PackedTypes,
  Struct,
  structifyArray,
  StructOrder,
  StructToDataType,
  uint16,
} from 'utils/structify'
import BinaryChartRenderer from './BinaryRenderer'
import Accordion from 'components/common/Accordion'
import Link from 'components/common/Link'
import { Button } from '@tovala/component-library'
import { exportAsCSV } from 'utils/exportAsCSV'

const ROUGH_EVENTS_PER_CYCLE = 30
const CYCLES_PER_SEARCH = 30

const SEARCH_REF_HASH_SIZE = ROUGH_EVENTS_PER_CYCLE * CYCLES_PER_SEARCH

interface CookingProgressSample extends Struct {
  chamberTemp_f: number
  barrelTemp_f: number
  cookTime: number
  relayState: number
}

type CookingProgressSampleFields = StructToDataType<CookingProgressSample>
const cookingProgressSampleFields: CookingProgressSampleFields = {
  chamberTemp_f: int16,
  barrelTemp_f: int16,
  cookTime: uint16,
  relayState: uint16,
}

const cookingProgressSampleOrder: StructOrder<CookingProgressSample> = [
  'chamberTemp_f',
  'barrelTemp_f',
  'cookTime',
  'relayState',
]

type Routine = {
  cookTime: number
  temperature: number
  mode: CookModes
}

type ManagedRoutine = Routine & {
  startCookTime?: number
  endCookTime?: number
}

type StartingEventPayload = {
  source: string
  sessionid: string
  serialNumber: string
  deviceGroupID: string
  deviceGroupName: string
  cookCycleID: string
  ovenid: string
  barcode: string
  scannedBarcode: string
  mode: string
  ovenMode: string
  setDuration: number
  setTemperature: number
  type: string
  routine: Routine[]
}

type ProgressEventPayload = {
  source: string
  sessionid: string
  serialNumber: string
  deviceGroupID: string
  deviceGroupName: string
  cookCycleID: string
  ovenid: string
  chefMode: string
  mode: string
  stepNumber: number
  temperature: number
  progressPercentComplete: number
  timeRemaining: number
  totalTime: number
  tempDataV1: string
}

type CookingCompleteEventPayload = {
  source: string
  sessionid: string
  serialNumber: string
  deviceGroupID: string
  deviceGroupName: string
  cookCycleID: string
  ovenid: string
  mode: string
  type: string
}

type BaseEvent<T> = {
  deviceID: string
  eventKey: string
  eventTimeMs: number
  eventType: string
  payload: T
  receiveTimeMs: number
  ttl: number
}

type StartingEvent = BaseEvent<StartingEventPayload>
type ProgressEvent = BaseEvent<ProgressEventPayload>
type CookingCompleteEvent = BaseEvent<CookingCompleteEventPayload>

type GroupedCookCycles = Map<string, OvenEvent[]>
function groupCookCycles(data: OvenEvent[]): GroupedCookCycles {
  const groupedCookEvents: GroupedCookCycles = new Map<string, OvenEvent[]>()

  for (const event of data) {
    const cookCycleId = event.cookCycleID!
    if (!cookCycleId) {
      continue
    }

    if (!groupedCookEvents.has(cookCycleId)) {
      groupedCookEvents.set(cookCycleId, [])
    }

    groupedCookEvents.get(cookCycleId)!.push(event)
  }

  return groupedCookEvents
}

type SplitOvenEvents = {
  startEvents: StartingEvent[]
  endEvents: CookingCompleteEvent[]
  progressEvents: ProgressEvent[]
}
function processEvents(data: OvenEvent[]): SplitOvenEvents {
  const sortedData = data.sort((a, b) => a.eventTimeMs - b.eventTimeMs)

  const startEvents = sortedData.filter(
    (event) => event.eventKey === 'cookingStarted',
  ) as StartingEvent[]
  const endEvents = sortedData.filter(
    (event) => event.eventKey === 'cookingComplete',
  ) as CookingCompleteEvent[]
  const progressEvents = sortedData.filter(
    (event) => event.eventKey === 'cookingStatusUpdate',
  ) as ProgressEvent[]

  return { startEvents, endEvents, progressEvents }
}

function getRoutines(startEvents: StartingEvent[]): ManagedRoutine[] {
  const lastStartEvent = startEvents[startEvents.length - 1]

  const routines: ManagedRoutine[] = []

  let lastEndTime = 0
  for (const routine of lastStartEvent.payload.routine) {
    const startCookTime = lastEndTime
    const endCookTime = startCookTime + routine.cookTime
    lastEndTime = endCookTime

    routines.push({
      ...routine,
      startCookTime,
      endCookTime,
    })
  }

  return routines
}

function getProgressSamples(tempDataV1: string): CookingProgressSample[] {
  let decoded: string
  try {
    decoded = decodeURLSafe(tempDataV1)
  } catch (e) {
    return []
  }

  const buf = new Uint8Array(decoded.length)
  for (let i = 0; i < decoded.length; i++) {
    buf[i] = decoded.charCodeAt(i)
  }

  const progressSample = structifyArray<CookingProgressSample>(
    buf,
    cookingProgressSampleFields,
    cookingProgressSampleOrder,
    EndianTypes.LITTLE_ENDIAN,
    PackedTypes.UNPACKED,
    DataWidth.WIDTH32,
  )

  return progressSample
}

function processProgressEvents(
  progressEvents: ProgressEvent[],
): CookingProgressSample[] {
  const progressSamples: CookingProgressSample[] = []

  for (const event of progressEvents) {
    const samples = getProgressSamples(event.payload.tempDataV1)
    progressSamples.push(...samples)
  }

  return progressSamples
}

function getMaxTime(
  routines: ManagedRoutine[],
  progressSamples: CookingProgressSample[],
): number {
  const lastProgressSample = progressSamples[progressSamples.length - 1]
  const lastProgressTime = lastProgressSample.cookTime

  const lastRoutine = routines[routines.length - 1]
  const lastRoutineEndTime = lastRoutine.endCookTime!

  return Math.max(lastRoutineEndTime, lastProgressTime) + 1
}

// This is a hack to add voids to the chart instead of 0s
const nullNumber: number = null as unknown as number
const nullNumberArray: [number, number] = null as unknown as [number, number]

function fillInMissingProgressSamples(
  progressSamples: CookingProgressSample[],
  maxTime: number,
): CookingProgressSample[] {
  const filledSamples: CookingProgressSample[] =
    new Array<CookingProgressSample>(maxTime)
      .fill({
        cookTime: 0,
        barrelTemp_f: nullNumber,
        chamberTemp_f: nullNumber,
        relayState: nullNumber,
      })
      .map((_, i) => ({ ..._, cookTime: i }))

  for (const sample of progressSamples) {
    filledSamples[sample.cookTime] = sample
  }

  return filledSamples
}

function renderRoutines(routines: ManagedRoutine[]): ReactNode[] {
  return routines.map((routine, i) => {
    return (
      <ReferenceArea
        key={i}
        fill={cookModeColors[routine.mode]}
        fillOpacity={i % 2 === 0 ? 0.15 : 0.3}
        ifOverflow="extendDomain"
        label={{
          value: convertToPrettyString(routine.mode),
        }}
        x1={routine.startCookTime}
        x2={routine.endCookTime}
        y1={0}
        y2={routine.temperature}
      />
    )
  })
}

const DOOR_BIT = 1 << 8
function renderDoorStates(
  samples: CookingProgressSample[],
  maxTime: number,
): ReactNode[] {
  const doorStates: ReactNode[] = []
  let lastDoorState = samples[0].relayState & DOOR_BIT

  for (let i = 1; i < samples.length; i++) {
    const sample = samples[i]
    const doorState = sample.relayState & DOOR_BIT

    if (doorState !== lastDoorState) {
      doorStates.push(
        <ReferenceLine
          label={{
            position:
              sample.cookTime < maxTime / 2
                ? 'insideTopLeft'
                : 'insideTopRight',
            value: doorState ? 'Door Open' : 'Door Closed',
          }}
          stroke="black"
          strokeDasharray="3 3"
          x={sample.cookTime}
        />,
      )
    }

    lastDoorState = doorState
  }

  return doorStates
}

type ProcessedCycle = {
  routines: ManagedRoutine[]
  filledSamples: CookingProgressSample[]
  maxTime: number
}
function processCycle(events: OvenEvent[]): ProcessedCycle {
  const processedEvents = processEvents(events)
  if (!processedEvents.startEvents.length) {
    throw new Error('Could not find start events')
  }

  if (!processedEvents.progressEvents.length) {
    throw new Error('Could not find progress events')
  }

  const routines = getRoutines(processedEvents.startEvents)
  const progressSamples = processProgressEvents(processedEvents.progressEvents)
  const maxTime = getMaxTime(routines, progressSamples)
  const filledSamples = fillInMissingProgressSamples(progressSamples, maxTime)

  return { routines, filledSamples, maxTime }
}

type ReferenceCycle = {
  cookTime: number
  refBarrelTemp_f: [number, number]
  refAvgBarrelTemp_f: number
  refChamberTemp_f: [number, number]
  refAvgChamberTemp_f: number

  comparedBarrelTemp_f: number
  comparedChamberTemp_f: number
}

function processReferenceCookCycles(
  groupedCookCycles: GroupedCookCycles,
  maxTime: number,
  referencedCookCycleId?: string,
) {
  const referenceData: ReferenceCycle[] = new Array<ReferenceCycle>(maxTime)
    .fill({
      cookTime: 0,
      refBarrelTemp_f: nullNumberArray,
      refAvgBarrelTemp_f: nullNumber,
      refChamberTemp_f: nullNumberArray,
      refAvgChamberTemp_f: nullNumber,
      comparedBarrelTemp_f: nullNumber,
      comparedChamberTemp_f: nullNumber,
    })
    .map((_, i) => ({ ..._, cookTime: i }))

  for (const cycleId of Array.from(groupedCookCycles.keys())) {
    const cycle = groupedCookCycles.get(cycleId)
    if (!cycle) {
      continue
    }

    let processedCycle
    try {
      processedCycle = processCycle(cycle)
    } catch {
      continue
    }

    const maxTimeDiff = maxTime - processedCycle.maxTime

    for (let i = 0; i < processedCycle.filledSamples.length; i++) {
      const sample = processedCycle.filledSamples[i]
      if (sample.barrelTemp_f === nullNumber) {
        continue
      }

      const offsetCookTime = sample.cookTime + maxTimeDiff

      if (!referenceData[offsetCookTime]) {
        continue
      }

      const refSample = referenceData[offsetCookTime]
      if (refSample.refBarrelTemp_f === nullNumberArray) {
        refSample.refBarrelTemp_f = [sample.barrelTemp_f, sample.barrelTemp_f]
        refSample.refAvgBarrelTemp_f = sample.barrelTemp_f
      }

      if (refSample.refChamberTemp_f === nullNumberArray) {
        refSample.refChamberTemp_f = [
          sample.chamberTemp_f,
          sample.chamberTemp_f,
        ]
        refSample.refAvgChamberTemp_f = sample.chamberTemp_f
      }

      refSample.refBarrelTemp_f[0] = Math.min(
        refSample.refBarrelTemp_f[0],
        sample.barrelTemp_f,
      )
      refSample.refBarrelTemp_f[1] = Math.max(
        refSample.refBarrelTemp_f[1],
        sample.barrelTemp_f,
      )
      refSample.refAvgBarrelTemp_f =
        (refSample.refBarrelTemp_f[0] + refSample.refBarrelTemp_f[1]) / 2
      refSample.refChamberTemp_f[0] = Math.min(
        refSample.refChamberTemp_f[0],
        sample.chamberTemp_f,
      )
      refSample.refChamberTemp_f[1] = Math.max(
        refSample.refChamberTemp_f[1],
        sample.chamberTemp_f,
      )
      refSample.refAvgChamberTemp_f =
        (refSample.refChamberTemp_f[0] + refSample.refChamberTemp_f[1]) / 2

      if (cycleId === referencedCookCycleId) {
        refSample.comparedBarrelTemp_f = sample.barrelTemp_f
        refSample.comparedChamberTemp_f = sample.chamberTemp_f
      }
    }
  }

  return referenceData
}

type MergedCookCycleSample = CookingProgressSample & ReferenceCycle

function mergeSamples(
  filledSamples: CookingProgressSample[],
  referenceData: ReferenceCycle[],
): MergedCookCycleSample[] {
  return filledSamples.map((sample, i) => {
    return {
      ...sample,
      ...referenceData[i],
    }
  })
}

const LABEL_WIDTH = 120

const relayNames: {
  [key in Models]: string[]
} = {
  airvala: [
    'AV Cooling Fan',
    'Cooling Fan',
    'Lamp',
    'Broiler',
    'Top',
    'Convection',
    'Boiler',
    'Convection Hi',
    'Door',
  ],
  gen2: [
    'Steam Solenoid',
    'Cooling Fan',
    'Lamp',
    'Broiler',
    'Top',
    'Convection',
    'Boiler',
    'Convection Hi',
    'Door',
  ],
}

const relayColors = [
  'brown',
  'red',
  'orange',
  '#cf913a',
  'green',
  'blue',
  'violet',
  'grey',
  '#72c5c9',
]

function readModelFromRoutineHash(routineHash: string) {
  const firstByte = parseInt(routineHash.slice(0, 2), 16)
  switch (firstByte) {
    case 0x00:
      return Models.GEN2
    case 0x01:
      return Models.AIR
    default:
      throw new Error('Unknown model')
  }
}

function CookCycle({
  cookCycleId,
  maxTime,
  routines,
  mergedSamples,
  processedCycle,
  lastCookCycleWithTemp,
  startingEvent,
  model,
  groupedRefEvents,
  referencedCookCycleId,
}: {
  cookCycleId: string
  maxTime: number
  routines: ManagedRoutine[]
  mergedSamples: MergedCookCycleSample[]
  processedCycle: ProcessedCycle
  lastCookCycleWithTemp: CookingProgressSample | undefined
  startingEvent: OvenEvent
  model: Models
  groupedRefEvents: GroupedCookCycles
  referencedCookCycleId: string | undefined
}) {
  const [hidden, setHidden] = useState<string[]>([])
  const id = useId()

  const referencedCookCycle = groupedRefEvents.get(referencedCookCycleId ?? '')
  const didComplete = (lastCookCycleWithTemp?.cookTime ?? 0) >= maxTime - 2

  return (
    <div className="rounded-lg shadow-lg p-4 mb-4 bg-slate-50">
      {/* Heading */}
      <div>
        <h2>
          {cookCycleId} - {new Date(startingEvent.eventTimeMs).toLocaleString()}
        </h2>

        {/* Basic info */}
        <div>
          <div>Model: {model}</div>
          <div>
            Routines:{' '}
            {routines.map((routine, i) => (
              <div key={i}>
                {i + 1}. {routine.mode} - {routine.temperature}°F for{' '}
                {routine.cookTime}s
              </div>
            ))}
          </div>
          <div>
            Total cycles with matching routine:{' '}
            {Array.from(groupedRefEvents.keys()).length - 1}
          </div>

          {didComplete ? (
            <div>Cooking completed</div>
          ) : (
            <div className="text-red-901">Cooking did not complete</div>
          )}
        </div>

        {/* Compare cycles info */}
        {referencedCookCycle && (
          <div>
            <hr />
            <div className="mt-2">
              Compared cycle: {referencedCookCycleId} -{' '}
              {new Date(referencedCookCycle[0].eventTimeMs).toLocaleString()}
            </div>
            <Link href={`/ovens/${referencedCookCycle[0].deviceID}`}>
              Device ID {referencedCookCycle?.[0].deviceID}
            </Link>
          </div>
        )}

        {/* Compare cycles */}
        <div className="flex justify-end gap-2">
          <div>
            <Button
              onClick={() => {
                type CSVSample = {
                  epoch: number
                  cookTime: number
                  barrelTemp_f: number
                  chamberTemp_f: number
                  relayState: string
                  refBarrelTempMin_f: number
                  refBarrelTempMax_f: number
                  refChamberTempMin_f: number
                  refChamberTempMax_f: number
                  refAvgBarrelTemp_f: number
                  refAvgChamberTemp_f: number
                  comparedBarrelTemp_f: number
                  comparedChamberTemp_f: number
                }
                const startingEventMs = startingEvent.eventTimeMs

                exportAsCSV<MergedCookCycleSample, CSVSample>(
                  mergedSamples,
                  (row) => {
                    const epoch = startingEventMs + row.cookTime * 1000
                    return {
                      epoch,
                      cookTime: row.cookTime,
                      barrelTemp_f: row.barrelTemp_f,
                      chamberTemp_f: row.chamberTemp_f,
                      relayState:
                        row.relayState !== nullNumber
                          ? "'" + row.relayState.toString(2).padStart(8, '0')
                          : '',
                      refBarrelTempMin_f: row.refBarrelTemp_f?.[0],
                      refBarrelTempMax_f: row.refBarrelTemp_f?.[1],
                      refChamberTempMin_f: row.refChamberTemp_f?.[0],
                      refChamberTempMax_f: row.refChamberTemp_f?.[1],
                      refAvgBarrelTemp_f: row.refAvgBarrelTemp_f,
                      refAvgChamberTemp_f: row.refAvgChamberTemp_f,
                      comparedBarrelTemp_f: row.comparedBarrelTemp_f,
                      comparedChamberTemp_f: row.comparedChamberTemp_f,
                    }
                  },
                  {
                    epoch: 'Epoch',
                    cookTime: 'Cook Time (s)',
                    barrelTemp_f: 'Barrel Temp (°F)',
                    chamberTemp_f: 'Chamber Temp (°F)',
                    relayState: 'Relay State',
                    refBarrelTempMin_f: 'Ref Barrel Temp Min (°F)',
                    refBarrelTempMax_f: 'Ref Barrel Temp Max (°F)',
                    refChamberTempMin_f: 'Ref Chamber Temp Min (°F)',
                    refChamberTempMax_f: 'Ref Chamber Temp Max (°F)',
                    refAvgBarrelTemp_f: 'Ref Avg Barrel Temp (°F)',
                    refAvgChamberTemp_f: 'Ref Avg Chamber Temp (°F)',
                    comparedBarrelTemp_f: 'Compared Barrel Temp (°F)',
                    comparedChamberTemp_f: 'Compared Chamber Temp (°F)',
                  },
                  [
                    'epoch',
                    'cookTime',
                    'barrelTemp_f',
                    'chamberTemp_f',
                    'relayState',
                    'refBarrelTempMin_f',
                    'refBarrelTempMax_f',
                    'refChamberTempMin_f',
                    'refChamberTempMax_f',
                    'refAvgBarrelTemp_f',
                    'refAvgChamberTemp_f',
                    'comparedBarrelTemp_f',
                    'comparedChamberTemp_f',
                  ],
                  'cook_cycle.csv',
                  [
                    ['Cook Cycle ID', cookCycleId],
                    ['Model', model],
                    ['Start Time', new Date(startingEventMs).toLocaleString()],
                    [
                      'Routines',
                      routines
                        .map(
                          (routine, i) =>
                            `${i + 1}. ${routine.mode} - ${routine.temperature}°F for ${routine.cookTime}s`,
                        )
                        .join(','),
                    ],
                    [
                      'Total cycles with matching routine',
                      (
                        Array.from(groupedRefEvents.keys()).length - 1
                      ).toString(),
                    ],
                    [
                      'Compared cycle',
                      referencedCookCycle
                        ? `${referencedCookCycleId},${new Date(
                            referencedCookCycle[0].eventTimeMs,
                          ).toLocaleString()},device ID,${referencedCookCycle[0].deviceID}`
                        : 'None',
                    ],
                    ['Cooking completed', didComplete ? 'Yes' : 'No'],
                  ],
                )
              }}
              size="small"
            >
              Export CSV
            </Button>
          </div>
        </div>
      </div>

      {/* Main chart */}
      <div className="w-full h-96">
        <ResponsiveContainer height={'100%'} width={'100%'}>
          <ComposedChart data={mergedSamples} syncId={id}>
            <XAxis dataKey="cookTime" />
            <YAxis width={LABEL_WIDTH} />
            <CartesianGrid stroke="#ccc" />

            <Line
              dataKey="barrelTemp_f"
              dot={false}
              hide={hidden.includes('barrelTemp_f')}
              isAnimationActive={false}
              name="Barrel Temp"
              stroke="blue"
              strokeWidth={2}
            />
            <Line
              dataKey="chamberTemp_f"
              dot={false}
              hide={hidden.includes('chamberTemp_f')}
              isAnimationActive={false}
              name="Chamber Temp"
              stroke="red"
              strokeWidth={2}
            />

            {renderRoutines(routines)}
            {renderDoorStates(mergedSamples, maxTime)}

            <Legend
              onClick={(e) => {
                setHidden((prev) => {
                  if (!e.dataKey) {
                    return prev
                  }

                  if (prev.includes(e.dataKey as string)) {
                    return prev.filter((key) => key !== e.dataKey)
                  }

                  return [...prev, e.dataKey as string]
                })
              }}
            />

            <Area
              dataKey={'refBarrelTemp_f'}
              fill="blue"
              fillOpacity={0.15}
              hide={hidden.includes('refBarrelTemp_f')}
              isAnimationActive={false}
              name="Ref Barrel Temp"
              strokeOpacity={0}
            />
            <Line
              dataKey={'refAvgBarrelTemp_f'}
              dot={false}
              hide={hidden.includes('refAvgBarrelTemp_f')}
              isAnimationActive={false}
              name="Ref Avg Barrel Temp"
              opacity={0.5}
              stroke="blue"
              strokeDasharray={'5 5'}
              strokeWidth={1}
            />
            <Area
              dataKey={'refChamberTemp_f'}
              fill="red"
              fillOpacity={0.15}
              hide={hidden.includes('refChamberTemp_f')}
              isAnimationActive={false}
              name="Ref Chamber Temp"
              strokeOpacity={0}
            />
            <Line
              dataKey={'refAvgChamberTemp_f'}
              dot={false}
              hide={hidden.includes('refAvgChamberTemp_f')}
              isAnimationActive={false}
              name="Ref Avg Chamber Temp"
              opacity={0.5}
              stroke="red"
              strokeDasharray={'5 5'}
              strokeWidth={1}
            />

            {referencedCookCycleId && [
              <Line
                key="comparedBarrelTemp_f"
                dataKey={'comparedBarrelTemp_f'}
                dot={false}
                hide={hidden.includes('comparedBarrelTemp_f')}
                isAnimationActive={false}
                name="Compared Barrel Temp"
                stroke="blue"
                strokeDasharray={'5 5'}
                strokeWidth={2}
              />,
              <Line
                key="comparedChamberTemp_f"
                dataKey={'comparedChamberTemp_f'}
                dot={false}
                hide={hidden.includes('comparedChamberTemp_f')}
                isAnimationActive={false}
                name="Compared Chamber Temp"
                stroke="red"
                strokeDasharray={'5 5'}
                strokeWidth={2}
              />,
            ]}

            {!didComplete && lastCookCycleWithTemp && (
              <ReferenceLine
                label={{
                  position:
                    lastCookCycleWithTemp.cookTime < maxTime / 2
                      ? 'insideTopLeft'
                      : 'insideTopRight',
                  value: 'End of data',
                }}
                stroke="black"
                strokeDasharray="3 3"
                x={lastCookCycleWithTemp.cookTime}
              />
            )}

            <Tooltip
              formatter={(value, name, item) => {
                const dataKey = item?.dataKey ?? ''

                if (
                  dataKey === 'barrelTemp_f' ||
                  dataKey === 'chamberTemp_f' ||
                  dataKey === 'refAvgBarrelTemp_f' ||
                  dataKey === 'refAvgChamberTemp_f' ||
                  dataKey === 'comparedBarrelTemp_f' ||
                  dataKey === 'comparedChamberTemp_f'
                ) {
                  return `${value}°F`
                }

                if (
                  dataKey === 'refBarrelTemp_f' ||
                  dataKey === 'refChamberTemp_f'
                ) {
                  const valArray = value as [number, number]
                  return `${valArray[0]}°F - ${valArray[1]}°F`
                }

                return value
              }}
              labelFormatter={(label) => {
                return `${label}s`
              }}
            />

            <Brush dataKey="cookTime" height={10} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      {/* Relay States */}
      <Accordion noOutline noPadding title={'Relay State'}>
        <div className="w-full h-72">
          <BinaryChartRenderer
            bitColors={relayColors}
            bitLabels={relayNames[model]}
            bits={relayNames[model].length}
            data={processedCycle.filledSamples}
            labelWidth={LABEL_WIDTH}
            syncId={id}
            xAxisDataKey="cookTime"
            yAxisDataKey="relayState"
          />
        </div>
      </Accordion>
    </div>
  )
}

function RenderedCookCycle({
  cookCycleId,
  startEvent,
  referencedCookCycleId,
}: {
  cookCycleId: string
  startEvent: OvenEvent
  referencedCookCycleId?: string
}) {
  const routineHash = startEvent.routineHash
  const { data } = useRoutineHash(routineHash, 0, SEARCH_REF_HASH_SIZE)

  const groupedRefEvents = groupCookCycles(data ?? [])
  if (!groupedRefEvents.has(cookCycleId)) {
    return (
      <div>
        <div>
          Could not find cook cycle {cookCycleId} for {routineHash}
        </div>
      </div>
    )
  }

  const originalEvents = groupedRefEvents.get(cookCycleId)!
  let processedCycle
  let referenceData

  try {
    processedCycle = processCycle(originalEvents)
    referenceData = processReferenceCookCycles(
      groupedRefEvents,
      processedCycle.maxTime,
      referencedCookCycleId,
    )
  } catch {
    return (
      <div>
        <div>
          Could not process cook cycle {cookCycleId} for {routineHash}
        </div>
      </div>
    )
  }

  const mergedSamples = mergeSamples(
    processedCycle.filledSamples,
    referenceData,
  )

  let lastCookCycleWithTemp
  for (let i = processedCycle.filledSamples.length - 1; i >= 0; i--) {
    const sample = processedCycle.filledSamples[i]
    if (sample.barrelTemp_f !== nullNumber) {
      lastCookCycleWithTemp = sample
      break
    }
  }

  return (
    <CookCycle
      cookCycleId={cookCycleId}
      groupedRefEvents={groupedRefEvents}
      lastCookCycleWithTemp={lastCookCycleWithTemp}
      maxTime={processedCycle.maxTime}
      mergedSamples={mergedSamples}
      model={readModelFromRoutineHash(originalEvents[0].routineHash!)}
      processedCycle={processedCycle}
      referencedCookCycleId={referencedCookCycleId}
      routines={processedCycle.routines}
      startingEvent={originalEvents[0]}
    />
  )
}

export default RenderedCookCycle
