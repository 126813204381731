import { useQuery } from '@tanstack/react-query'
import {
  CircleLoader,
  Combobox,
  InformationCircleIcon,
  Input,
  Modal,
  ModalHeader,
  Tooltip,
  MultiCombobox,
  MultiInputbox,
} from '@tovala/component-library'
import { clsx } from 'clsx'
import { useState } from 'react'
import {
  fetchImages,
  fetchThingGroups,
  prePublishOTAAffected,
  publishOTA,
  getAuthToken,
} from 'utils/oatsApi'

type Option = {
  label: string
  value: unknown
}

type Props = {
  defaultImage?: string
  defaultGroups?: string[]
  defaultDevices?: string[]
  defaultCustomId?: string
  lockImage?: boolean
  lockGroups?: boolean
  lockDevices?: boolean
  onPublish?: (id: string) => void
}

const ImagePublish = (props: Props = {}) => {
  const {
    data: groupsData,
    isLoading: groupsIsLoading,
    error: groupsError,
  } = useQuery({
    queryKey: ['thingGroups'],
    queryFn: async () => {
      const bearerToken = getAuthToken()
      return fetchThingGroups(bearerToken)
    },
  })

  const {
    data: imageData,
    isLoading: imageIsLoading,
    error: imageError,
  } = useQuery({
    queryKey: ['images'],
    queryFn: async () => {
      const bearerToken = getAuthToken()
      return fetchImages(bearerToken)
    },
  })

  const [selectedImage, setSelectedImage] = useState<Option | null>(
    props.defaultImage
      ? { label: props.defaultImage, value: props.defaultImage }
      : null,
  )
  const [selectedGroups, setSelectedGroups] = useState<Option[] | null>(
    props.defaultGroups?.map((group) => ({
      label: group,
      value: group,
    })) ?? null,
  )
  const [selectedDevices, setSelectedDevices] = useState<Option[] | null>(
    props.defaultDevices?.map((device) => ({
      label: device,
      value: device,
    })) ?? null,
  )
  const [customId, setCustomId] = useState<string>(props.defaultCustomId ?? '')

  const [totalAffected, setTotalAffected] = useState<number | null>(null)
  const [publishedId, setPublishedId] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <div>
      <div className="bg-slate-50 shadow-lg max-w-2xl p-4 rounded-lg h-max">
        <div className="flex flex-col">
          <div className="flex flex-col gap-4 mt-2">
            <div
              className={clsx('flex flex-col', {
                'opacity-50 pointer-events-none': imageIsLoading || imageError,
              })}
            >
              <Tooltip
                trigger={
                  <label className="text-sm ml-1 w-max flex items-center">
                    Image
                    <span className="text-red">*</span>
                    <span className="ml-2">
                      {imageIsLoading && <CircleLoader loaderStyle="dark" />}
                    </span>
                  </label>
                }
              >
                Select the image to publish
              </Tooltip>
              <Combobox
                onChange={(value) => {
                  if (props.lockImage) {
                    return
                  }
                  setSelectedImage(value)
                }}
                options={
                  imageData?.map((image) => ({
                    label: image.file_name,
                    value: image.file_name,
                  })) ?? []
                }
                value={selectedImage}
              />
              {!!imageError && (
                <div className="text-red">Failed to fetch images</div>
              )}
            </div>
            <div
              className={clsx('flex flex-col', {
                'opacity-50 pointer-events-none':
                  groupsIsLoading || groupsError,
              })}
            >
              <Tooltip
                trigger={
                  <label className="text-sm ml-1 w-max flex items-center">
                    Groups
                    <span className="ml-2">
                      {groupsIsLoading && <CircleLoader loaderStyle="dark" />}
                    </span>
                  </label>
                }
              >
                Select the groups to publish to
              </Tooltip>
              <MultiCombobox
                onChange={(newValue) => {
                  if (props.lockGroups) {
                    return
                  }
                  setSelectedGroups(newValue)
                }}
                options={
                  groupsData?.map((group) => ({
                    label: group.group_name,
                    value: group.group_name,
                  })) ?? []
                }
                value={selectedGroups}
              />
              {!!groupsError && (
                <div className="text-red">Failed to fetch groups</div>
              )}
            </div>
            <div className="flex flex-col">
              <Tooltip
                trigger={
                  <label className="text-sm ml-1 w-max flex items-center">
                    Devices
                  </label>
                }
              >
                Enter the devices to publish to
              </Tooltip>
              <MultiInputbox
                onChange={(newValue) => {
                  if (props.lockDevices) {
                    return
                  }
                  setSelectedDevices(newValue)
                }}
                value={selectedDevices}
              />
            </div>
            <div className="flex flex-col">
              <Tooltip
                trigger={
                  <label className="text-sm ml-1 w-max flex items-center">
                    Custom ID
                    <div className="ml-2 w-3 h-3">
                      <InformationCircleIcon />
                    </div>
                  </label>
                }
              >
                Enter a custom ID for this OTA. If left blank, a random ID will
                be generated.
              </Tooltip>
              <Input
                onChange={(event) => setCustomId(event.target.value)}
                value={customId}
              />
            </div>
            <div>
              <div className="text-xs text-red">
                * Required fields for OTA publish. At least 1 group or device
                must be selected.
              </div>
              <div className="text-sm text-red"></div>
            </div>
            <div className="flex flex-row justify-end gap-4">
              <button
                className={clsx(
                  'bg-gray-500 text-white rounded-lg p-2 w-20 h-10 flex justify-center items-center',
                  {
                    'cursor-not-allowed':
                      !selectedImage ||
                      (!selectedGroups?.length && !selectedDevices?.length) ||
                      loading,
                    'bg-orange-1':
                      selectedImage &&
                      (selectedGroups?.length || selectedDevices?.length) &&
                      !loading,
                  },
                )}
                onClick={async () => {
                  if (
                    !selectedImage ||
                    (!selectedGroups?.length && !selectedDevices?.length) ||
                    loading
                  ) {
                    return
                  }
                  const bearerToken = getAuthToken()
                  const devices =
                    selectedDevices?.map((device) => device.label) ?? []
                  const groups =
                    selectedGroups?.map((group) => group.label) ?? []

                  setLoading(true)
                  let affected
                  try {
                    affected = await prePublishOTAAffected(
                      bearerToken,
                      devices,
                      groups,
                    )
                  } catch (error) {
                    setErrorMessage((error as Error).message)
                    setLoading(false)
                    return
                  }

                  setTotalAffected(affected)
                }}
              >
                {loading ? <CircleLoader loaderStyle="white" /> : 'Publish'}
              </button>
            </div>
            <div>
              {errorMessage && <div className="text-red">{errorMessage}</div>}
              {publishedId && (
                <div>
                  <div className="text-green ml-1">
                    Successfully published OTA. ID:
                  </div>
                  <div>
                    <Input readOnly value={publishedId} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {totalAffected !== null && (
        <Modal
          onCloseModal={() => {
            setLoading(false)
            setTotalAffected(null)
          }}
        >
          <ModalHeader
            onClickClose={() => {
              setLoading(false)
              setTotalAffected(null)
            }}
          >
            <h1 className="text-xl font-bold">Publish OTA</h1>
          </ModalHeader>
          <div className="m-4">
            <div className="text-lg font-bold">
              Are you sure you want to publish this OTA?
            </div>
            <div>
              This will affect{' '}
              <span className="font-bold">{totalAffected}</span> devices
            </div>
            <div className="flex flex-row justify-end gap-4 mt-4">
              <button
                className="bg-orange-1 text-white rounded-lg p-2"
                onClick={async () => {
                  const bearerToken = getAuthToken()
                  const devices =
                    selectedDevices?.map((device) => device.label) ?? []
                  const groups =
                    selectedGroups?.map((group) => group.label) ?? []

                  const customIdValue = customId.trim() ? customId : null
                  setTotalAffected(null)
                  let resp
                  try {
                    resp = await publishOTA(
                      bearerToken,
                      devices,
                      groups,
                      selectedImage?.label ?? '',
                      customIdValue,
                    )
                  } catch (error) {
                    setErrorMessage((error as Error).message)
                    setLoading(false)
                    return
                  }

                  setPublishedId(resp)
                  setLoading(false)
                  if (props.onPublish) {
                    props.onPublish(resp)
                  }
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default ImagePublish
