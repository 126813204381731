export enum EndianTypes {
  LITTLE_ENDIAN,
  BIG_ENDIAN,
}

export enum PackedTypes {
  UNPACKED,
  PACKED,
}

export enum DataWidth {
  WIDTH8 = 1,
  WIDTH16 = 2,
  WIDTH32 = 4,
  WIDTH64 = 8,
}

export interface StructDataType<T> {
  name: string
  size: number
  alignment: number
  /** Returns the number of bytes written to the buffer */
  serialize: (
    value: T,
    uint8Arr: Uint8Array,
    offset: number,
    endian: EndianTypes,
  ) => number
  deserialize: (uint8Arr: Uint8Array, offset: number, endian: EndianTypes) => T
}

export interface Struct {}

export type StructToDataType<T extends Struct | Struct[]> = T extends Struct[]
  ? StructToDataType<T[0]>[]
  : {
      [K in keyof T]: StructDataType<T[K]>
    }

export type StructOrder<T extends Struct> = (keyof T)[]
